import React, {memo, useState} from 'react';

import PropTypes from 'prop-types';
import {find, pipe, prop, propEq, propOr} from 'ramda';
import {Trans} from 'react-i18next';
import {MAX_FIRST_TASK_FILE_UPLOAD} from 'config';
import {Button} from 'modules/layout';
import {ConfirmRemoveFileModal} from 'modules/tasks-wizard';

import {Alert, Flex, Modal} from '@renofi/components-internal';

const getItemField = (items, id, field) => {
  if (!(items && items.length && id)) {
    return null;
  }
  return pipe(find(propEq('id', id)), prop(field))(items);
};

const getRejectionMessage = ({documentId, taskId} = {}, tasks) =>
  pipe(
    find(propEq('id', taskId)),
    propOr([], 'documents'),
    find(propEq('id', documentId)),
    propOr(null, 'rejectionDetails'),
  )(tasks);

const getAdditionalRequestMessage = ({taskId}, tasks) =>
  pipe(
    find(propEq('id', taskId)),
    propOr('', 'additionalRequestMessage'),
  )(tasks);

const Modals = ({
  tasks,
  loading,
  children,
  rejection,
  removeIds: {documentId, taskId},
  addFilesData: {files = [], taskId: addTaskId, taskTitle},
  additionalRequest,
  onAcceptFiles,
  onConfirmUpload,
  onShowPrompt,
  onRemoveDocument,
  onCloseRejection,
  onCloseAdditionalRequest,
}) => {
  const [detailId, setDetailId] = useState();
  const hasNoTasksFilled = !tasks.some((t) => !!t.documents.length);
  const confirmUploadVisible =
    hasNoTasksFilled && files.length > MAX_FIRST_TASK_FILE_UPLOAD;
  const removalVisible =
    Boolean(documentId && taskId) && tasks.some(({id}) => id === taskId);
  const rejectionVisible =
    Boolean(rejection?.documentId && rejection?.taskId) &&
    tasks.some(({id}) => id === rejection?.taskId);
  return (
    <>
      {children}
      <Modal
        header="That’s a lot of documents"
        footer={
          <Flex justifyContent="flex-end" alignItems="flex-end">
            <Button
              onClick={() => onAcceptFiles({files, taskId: addTaskId})}
              variant="outline">
              <Trans>Yes, continue uploading</Trans>
            </Button>
            <Button
              css={{marginLeft: 12}}
              onClick={() => onConfirmUpload({})}
              variant="primary">
              <Trans>No, Cancel upload</Trans>
            </Button>
          </Flex>
        }
        onClose={() => onConfirmUpload({})}
        show={confirmUploadVisible}>
        <p>
          Are you sure all of these documents belong to{' '}
          <strong>{taskTitle}</strong> task?
        </p>
        <ul>
          {files.map(({name}) => (
            <li key={name}>{name}</li>
          ))}
        </ul>
        <Alert variant="info">
          Uploading documents to the wrong task extends review time.
        </Alert>
      </Modal>
      <ConfirmRemoveFileModal
        loading={loading}
        onClose={() => onShowPrompt({})}
        onAccept={() => onRemoveDocument({documentId, taskId})}
        onReject={() => onShowPrompt({})}
        show={removalVisible}
      />
      <Modal
        show={Boolean(detailId)}
        header={getItemField(tasks, detailId, 'title')}
        onClose={() => setDetailId()}
        footer={
          <Button block onClick={() => setDetailId()} variant="primary">
            <Trans>close</Trans>
          </Button>
        }>
        {getItemField(tasks, detailId, 'requirements')}
      </Modal>
      <Modal
        narrow
        show={rejectionVisible}
        header="A file requires your attention"
        footer={
          <Flex justifyContent="flex-end">
            <Button
              name="Rejected document details modal"
              onClick={() => onCloseRejection()}
              variant="primary">
              <Trans>OK</Trans>
            </Button>
          </Flex>
        }
        onClose={() => onCloseRejection()}>
        <p>{getRejectionMessage(rejection, tasks)}</p>
      </Modal>
      <Modal
        narrow
        show={Boolean(additionalRequest.taskId)}
        header="A task requires your attention"
        footer={
          <Flex justifyContent="flex-end">
            <Button
              name="Task additional request modal"
              onClick={() => onCloseAdditionalRequest()}
              variant="primary">
              <Trans>OK</Trans>
            </Button>
          </Flex>
        }
        onClose={() => onCloseAdditionalRequest()}>
        <p>{getAdditionalRequestMessage(additionalRequest, tasks)}</p>
      </Modal>
    </>
  );
};

Modals.propTypes = {
  addFilesData: PropTypes.shape({
    files: PropTypes.array,
    taskId: PropTypes.string,
    taskTitle: PropTypes.string,
  }),
  loading: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  tasks: PropTypes.arrayOf(PropTypes.object),
  rejection: PropTypes.object,
  removeIds: PropTypes.object.isRequired,
  additionalRequest: PropTypes.object,
  onAcceptFiles: PropTypes.func.isRequired,
  onConfirmUpload: PropTypes.func.isRequired,
  onRemoveDocument: PropTypes.func.isRequired,
  onShowPrompt: PropTypes.func.isRequired,
  onCloseRejection: PropTypes.func.isRequired,
  onCloseAdditionalRequest: PropTypes.func.isRequired,
};

Modals.defaultProps = {
  rejection: {},
  additionalRequest: {},
};

export default memo(Modals);
