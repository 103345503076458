export const taskTypes = {
  additional_documents: 'additional_documents',
  employment_history: 'employment_history',
  product_details_confirmation: 'product_details_confirmation',
  certificate_acknowledgement: 'certificate_acknowledgement',
  borrowers_onboarding: 'borrowers_onboarding',
  soft_credit_check: 'soft_credit_check',
  personal_info_and_residency: 'personal_info_and_residency',
  real_estate_owned: 'real_estate_owned',
  identity_verification: 'identity_verification',
  income: 'income',
};
