import {Fragment, useMemo} from 'react';

import {groupBy, keys} from 'ramda';
import {formatDuration, intervalToDuration} from 'date-fns';

import capitalize from '@renofi/utilities/src/capitalize';
import formatMoney from '@renofi/utilities/src/formatMoney';
import noop from '@renofi/utilities/src/noop';
import getYesNoFromBool from '@renofi/utilities/src/getYesNoFromBool';
import {formatDate} from '@renofi/utilities/src/dates';
import {Box, Flex, Text} from '@renofi/components-internal';
import {TaskDocument} from '@renofi/modules-internal';
import {basic80} from '@renofi/theme';

import {DOCUMENT_TYPES_NICKNAME_MAP} from '../../constants';

import {Label, Value, HistoryWrapper, Card, DocumentCard} from './styled';
import {sumAllIncomes} from './utils';

const DEFAULT_VALUE = '—';

const getYesNo = (value) => capitalize(getYesNoFromBool(value));

const HistoryItem = ({
  areActionsDisabled = true,
  history = {},
  internal = false,
  onAccept,
  onClick = noop,
  onClickDocument,
  onReject,
  showDocuments,
}) => {
  const {
    currentEmployment,
    documents = [],
    startDate,
    timeInRoleAsMonths,
    ...params
  } = history;
  const prefix = currentEmployment ? 'Current' : 'Previous';
  const end = currentEmployment ? new Date() : new Date(params?.endDate);
  const start = new Date(startDate);
  const duration = intervalToDuration({start, end});
  const timeAtJob = formatDuration(duration, {
    format: ['years', 'months'],
  });
  const totalIncome = sumAllIncomes(history);

  const timeInRole = useMemo(() => {
    if (!currentEmployment) {
      return DEFAULT_VALUE;
    }

    const months = timeInRoleAsMonths % 12;
    const years = Math.floor(timeInRoleAsMonths / 12);
    return `${years} years ${months} months`;
  }, [currentEmployment, timeInRoleAsMonths]);

  const groupedDocuments = useMemo(() => {
    return groupBy(({documentType}) => documentType, documents);
  }, [JSON.stringify(documents)]);

  return (
    <>
      <Card mb={3}>
        <HistoryWrapper flexWrap="wrap" mb={2} onClick={() => onClick(history)}>
          <Label>{prefix} employment:</Label>
          <Value>{params.employerName || DEFAULT_VALUE},</Value>

          <Label>Position:</Label>
          <Value>{params.businessRole || DEFAULT_VALUE},</Value>
          {internal ? (
            <>
              <Label>Start date:</Label>
              <Value>{formatDate(startDate)}</Value>

              <Label>End date:</Label>
              <Value>
                {currentEmployment ? 'N/A' : formatDate(params.endDate)}
              </Value>
            </>
          ) : null}

          <Label>Time at job:</Label>
          <Value>{timeAtJob},</Value>

          {currentEmployment && (
            <>
              <Label>Time in line of work:</Label>
              <Value>{timeInRole}</Value>
              <Label>Employed by family member:</Label>
              <Value>{getYesNo(params.employedByParty)},</Value>
            </>
          )}

          <Label>Business owner/self-employed:</Label>
          <Value>{getYesNo(params.selfEmployed)},</Value>

          <Label>Total gross monthly income:</Label>
          <Value>{formatMoney(totalIncome, {fractional: false})}</Value>
        </HistoryWrapper>
      </Card>

      {Boolean(showDocuments && documents?.length) ? (
        <Box mb={3}>
          {keys(groupedDocuments).map((key) => {
            const groupTitle = DOCUMENT_TYPES_NICKNAME_MAP[key] || key;
            const documents = groupedDocuments[key] || [];

            return (
              <Fragment key={key}>
                <Text color={basic80} mb={2} mt={3} fontSize={16}>
                  {groupTitle}
                </Text>

                <Flex
                  width={1}
                  flexDirection={['column', 'row']}
                  flexWrap={['initial', 'wrap']}>
                  {documents.map((doc) => {
                    return (
                      <DocumentCard mb={3} internal={internal} key={doc.id}>
                        <TaskDocument
                          areActionsDisabled={areActionsDisabled}
                          onClick={() => onClickDocument(doc.id)}
                          immutable
                          internal={internal}
                          onAccept={onAccept}
                          onReject={onReject}
                          showDownloadIcon={internal}
                          {...doc}
                        />
                      </DocumentCard>
                    );
                  })}
                </Flex>
              </Fragment>
            );
          })}
        </Box>
      ) : null}
    </>
  );
};

export default HistoryItem;
