import React, {Fragment} from 'react';

import {filter, last, pathOr, pipe, propEq} from 'ramda';
import {useAppraisalData} from 'lib/appraisal';
import {FacetIntro} from 'modules/layout';

import {basic80, isMobile, successDark, warningDark} from '@renofi/theme';
import {Alert, Box, Flex, Icon, Text} from '@renofi/components-internal';
import {TaskDocument} from '@renofi/modules-internal';
import {useToggledBorrowerProject} from '@renofi/graphql';

import PastFutureDate from './PastFutureDate';
import {Order, Separator} from './styled';
import {getIntroTextByOrder} from './utils';

const NO_VALUE = 'To be confirmed';

const getReportType = pipe(
  filter(propEq('documentType', 'appraisal_report_document')),
  last,
);

const isReportReviewFinished = pipe(
  pathOr(null, ['timeline', 'appraisalReportReviewFinishedAt']),
  Boolean,
);

const AppraisalOrder = () => {
  const {appraisalOrder} = useAppraisalData();
  const {project} = useToggledBorrowerProject();
  const {
    inspectionAppointmentAt,
    inspectionCompletedAt,
    reportReceivedAt,
    submittedAt,
    appraisalReportDocuments = [],
  } = appraisalOrder || {};

  const isCompleted = isReportReviewFinished(project);
  const iconColor = isCompleted ? successDark : warningDark;
  const inspectionDate = inspectionAppointmentAt || inspectionCompletedAt;
  const amcName = pathOr(NO_VALUE, ['amc', 'name'], appraisalOrder);

  const reportDocument = getReportType(appraisalReportDocuments);
  const introText = getIntroTextByOrder(appraisalOrder, isCompleted);

  const showDocument = isCompleted && Boolean(reportDocument);

  return (
    <Fragment>
      <FacetIntro>
        <Text mb={3} fontWeight={700} color={basic80} fontSize={24}>
          Property appraisal
        </Text>
        <Alert variant="info">{introText}</Alert>
      </FacetIntro>
      <Order isCompleted={isCompleted}>
        <Flex alignItems="center">
          <Icon name="helpClinic" color={iconColor} />

          <Text ml={2}>Appraisal order date:</Text>
          <Text color={basic80} ml={1}>
            {submittedAt ? <PastFutureDate value={submittedAt} /> : NO_VALUE}
          </Text>
        </Flex>
        <Separator isCompleted={isCompleted} />

        <Flex alignItems="center">
          <Icon name="calendarMonth" color={iconColor} />

          <Text ml={2}>Inspection date:</Text>
          <Text color={basic80} ml={1}>
            {inspectionDate ? (
              <PastFutureDate value={inspectionDate} />
            ) : (
              NO_VALUE
            )}
          </Text>
        </Flex>
        <Separator isCompleted={isCompleted} />

        {Boolean(reportReceivedAt) ? (
          <Fragment>
            <Flex alignItems="center">
              {isMobile() ? (
                <Box mr={2}>
                  <Icon name="calendarMonth" color={iconColor} />
                </Box>
              ) : null}

              <Text>Delivery date:</Text>
              <Text color={basic80} ml={1}>
                <PastFutureDate value={reportReceivedAt} />
              </Text>
            </Flex>
            <Separator isCompleted={isCompleted} />
          </Fragment>
        ) : null}

        <Flex alignItems="center">
          <Icon name="locationHome" color={iconColor} />

          <Text ml={2}>Appraiser:</Text>
          <Text color={basic80} mr={16} ml={1}>
            {amcName}
          </Text>
        </Flex>
      </Order>
      {showDocument ? (
        <Box px={3} my={3} width={0.65}>
          <TaskDocument
            {...reportDocument}
            immutable
            status="processing_file"
            openTabOnClick
          />
        </Box>
      ) : null}
    </Fragment>
  );
};

export default AppraisalOrder;
