import {
  GOVERNMENT_ID,
  PERSONAL_INFO_AND_RESIDENCY,
} from '../Eligibility/constants';

export const OUTSTANDING_LABELS = {
  soft_credit_check: 'get started',
  personal_info_and_residency: 'add details',
  real_estate_owned: 'add details',
  assets: 'add assets',
};

export const PENDING_LABELS = {
  soft_credit_check: 'edit borrowers',
  personal_info_and_residency: 'Edit personal details',
  real_estate_owned: 'edit details',
  assets: 'edit assets',
};

export const CONTINUE_LABELS = {
  personal_info_and_residency: 'continue',
  real_estate_owned: 'continue',
  assets: 'continue',
};

export const SOFT_CREDIT_CHECK = 'soft_credit_check';
export const ELIGIBILITY_TASK_TYPES = [
  GOVERNMENT_ID,
  PERSONAL_INFO_AND_RESIDENCY,
];

export const BADGE_VARIANTS = {
  completed: 'successInverse',
  blocked: 'danger',
  pending: 'warning',
  default: 'default',
};
