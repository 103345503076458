import styled from '@emotion/styled';

import {Flex} from '@renofi/components-internal';
import {maxSmall} from '@renofi/theme/src/breakpoints';

export const StyledFlex = styled(Flex)({
  position: 'relative',
  flexDirection: 'column',
  minHeight: '100vh',
  alignItems: 'flex-end',
  rowGap: '50px',
  justifyContent: 'space-between',
  background:
    'linear-gradient(180deg, #F7FCFF 0%, #E9F5FD 75.52%, #FCFEFF 100%)',
  [maxSmall]: {
    justifyContent: 'space-between',
    paddingTop: '85px',
  },
});

export const StyledBG = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  justifyContent: 'flex-end',
});

export const Container = styled(Flex)({
  width: 'calc(100% - 300px)',
  [maxSmall]: {
    width: '100%',
  },
});

export const FooterWrapper = styled(Flex)({
  paddingTop: 24,
  paddingBottom: 24,
  background: '#E7F9E9',
  paddingRight: '8px',
  paddingLeft: 308,
  justifyContent: 'center',
  [maxSmall]: {
    paddingLeft: '8px',
  },
});
