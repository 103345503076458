import React, {useEffect} from 'react';

import PropTypes from 'prop-types';
import {useParams} from 'react-router';
import {
  WizardUploadTask,
  useApplicationState,
  useTaskHandlers,
} from 'modules/project';
import {FacetIntro} from 'modules/layout';

import {useProjectTasks} from '@renofi/graphql';
import {Alert} from '@renofi/components-internal';
import Card from '@renofi/components-internal/src/next/Card';

import AdditionalDocumentsWizard from './components/Wizard';

const AdditionalDocuments = ({onTaskComplete, projectId}) => {
  const {facet} = useParams();

  const {tasks, fetch: fetchTasks} = useProjectTasks({lazy: true});
  const {onShowPrompt} = useTaskHandlers({tasks, projectId, facet});
  const {onShowRejection} = useApplicationState();

  useEffect(() => {
    if (projectId) {
      fetchTasks({variables: {projectId, facet}});
    }
  }, [projectId]);

  return (
    <Card p={0}>
      <FacetIntro>
        <Alert>
          Additional documents are required for your RenoFi loan application.
          Please upload these documents to avoid any unnecessary delays with
          your loan application.
        </Alert>
      </FacetIntro>

      {tasks.map((task) => {
        return (
          <WizardUploadTask
            key={task.id}
            onRemoveFile={(value) =>
              onShowPrompt({documentId: value, taskId: task.id})
            }
            onShowRejection={(documentId) =>
              onShowRejection({documentId, taskId: task.id})
            }
            onTaskComplete={onTaskComplete}
            showDetails
            task={task}
            wizard={AdditionalDocumentsWizard}
            wizardProps={{
              header: 'Additional documents',
            }}
          />
        );
      })}
    </Card>
  );
};

AdditionalDocuments.propTypes = {
  onTaskComplete: PropTypes.func,
  projectId: PropTypes.string.isRequired,
};

export default AdditionalDocuments;
