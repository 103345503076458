import {useCallback} from 'react';

import {useApolloClient} from '@apollo/client';
import {path, propOr} from 'ramda';

import {GET_PROJECT_TASKS} from '@renofi/graphql';
import {useTasksOverview} from '@renofi/graphql/src/hooks';
import {sendEvent} from '@renofi/utilities/src/analytics';

import logger from '../../logger';
import noop from '../../noop';

import useChannel from './useChannel';

const QUERY_PARAMS = {fetchPolicy: 'no-cache', lazy: true};
const TOTAL_PATH = ['data', 'projectById', 'tasksOverview', 'outstandingTotal'];

export default function useTaskStatusUpdated(projectId, onUpdate = noop) {
  const client = useApolloClient();
  const {fetch: fetchTasksOverview} = useTasksOverview(QUERY_PARAMS);

  const onTaskStatusUpdated = useCallback(
    async (event) => {
      logger.debug('TASK_STATUS_UPDATED', event);
      const facet = propOr(null, 'facet', event);
      const taskType = propOr(null, 'task_type', event);

      if (!facet || !projectId) {
        return;
      }

      const rsp = await fetchTasksOverview({variables: {id: projectId}});
      const outstandingTotal = path(TOTAL_PATH, rsp) || -1;
      if (outstandingTotal === 0) {
        sendEvent('Secure/No-Outstanding-Tasks', {taskType});
      }

      const [tasksRsp] = await client.refetchQueries({
        include: [
          {
            query: GET_PROJECT_TASKS,
            variables: {projectId, facet},
          },
        ],
      });
      onUpdate({...tasksRsp, facet});
    },
    [projectId],
  );

  useChannel({
    channelName: projectId && `task_status_updated--project=${projectId}`,
    onUpdate: onTaskStatusUpdated,
  });

  return {};
}
