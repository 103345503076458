import {last} from 'ramda';

import reduceSteps from './reduceSteps';

export default ({steps, step: currentStep}) => {
  const flattened = steps.reduce(reduceSteps, []);

  const lastItem = last(flattened);
  const isLastStep = Boolean(lastItem?.id === currentStep && lastItem?.isChild);
  const isFirstStep = Boolean(flattened[0]?.id === currentStep);

  return {
    final: isLastStep,
    finalLabel: 'I’m done with this task',
    hidePrev: isFirstStep,
  };
};
