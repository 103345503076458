import React, {useContext, useEffect} from 'react';

import PropTypes from 'prop-types';
import {Redirect, Route, Switch} from 'react-router-dom';
import {pathOr} from 'ramda';
import {useUnleashContext, useFlag} from '@unleash/proxy-client-react';
import {useFeatureFlagEvents} from 'lib/hooks';
import lazy from 'lib/asyncComponent';
import {Page, Drawer} from 'modules/layout';
import Onboarding from 'modules/onboarding';

import {useGetUser, useToggledBorrowerProject} from '@renofi/graphql/src/hooks';
import {
  Loader,
  RenoFiDesignCardMobile,
  Footer,
} from '@renofi/components-internal';
import {FeatureFlags} from '@renofi/modules-internal';
import {actionRegular, renofiBlue} from '@renofi/theme/src/colors';
import {setAnalyticsUser} from '@renofi/utilities/src/analytics';
import {Context as ConfigContext} from '@renofi/utilities/src/config';

import Delighted from '../../../delighted';

import {StyledFlex, Container, StyledBG, FooterWrapper} from './styled';
import {getRedirectPathByStatus} from './utils';
import BackImg from './BackImg';

const Project = lazy(
  () => import(/* webpackChunkName: "project" */ 'modules/project'),
);

const Tasks = lazy(
  () => import(/* webpackChunkName: "tasks" */ 'modules/tasks'),
);

const Lender = lazy(
  () => import(/* webpackChunkName: "lender" */ 'modules/lender'),
);

const Renovation = lazy(
  () => import(/* webpackChunkName: "renovation" */ 'modules/renovation'),
);

const Review = lazy(
  () => import(/* webpackChunkName: "review" */ 'modules/review'),
);

const Routes = ({impersonation}) => {
  const {user} = useGetUser();
  const {loading, project} = useToggledBorrowerProject();
  const config = useContext(ConfigContext);
  const {PRODUCTION} = config;
  const isRenoFiDesignEnabled = useFlag('secure-renofi-design');

  useFeatureFlagEvents();
  const updateContext = useUnleashContext();

  useEffect(() => {
    const borrowerId = pathOr(null, ['borrower', 'id'], project);

    if (borrowerId) {
      const {renovationCost} = project;
      updateContext({
        userId: borrowerId,
        renovationCost,
      });
    }

    if (Boolean(project && user) && PRODUCTION && !impersonation) {
      const {id, email, firstName, lastName} = user;
      const {loanType} = project;
      setAnalyticsUser({id, email, firstName, lastName, loanType});
    }
  }, [project, user]);

  if (loading) {
    return <Loader />;
  }

  return (
    <Page>
      <StyledFlex width={1}>
        <Container flexDirection="column" alignItems="center">
          <Drawer />
          {isRenoFiDesignEnabled && <RenoFiDesignCardMobile />}
          <Switch>
            <Route exact path="/steps/:step(2)" component={Lender} />
            <Route exact path="/steps/:step(3)" component={Renovation} />
            <Route
              exact
              path="/steps/:step(3)/:action(add_update)/:renovationUpdateStatus(expected_renovation_start|behind_schedule|on_schedule|ahead_of_schedule|renovation_completed|renovation_started)?"
              component={Renovation}
            />
            <Route exact path="/steps/:step(4)" component={Review} />

            <Route exact path="/steps/:step(1)" component={Project} />
            <Route
              path="/steps/:step(1)/:facet(additional_documents|product_confirmation|renovation|contractor|borrower_information)"
              component={Tasks}
            />
            {/* TODO: investigate if it is possible to replace contractorId with taskId to unify routes */}
            <Route
              exact
              path="/:facet(contractor)/:contractorId/:step?"
              component={Tasks}
            />
            <Route
              exact
              path="/:facet(additional_documents|appraisal|borrower_information|product_confirmation)/:taskId?/:step?/:propertyId?/:type?"
              component={Tasks}
            />
            <Route exact path="/feature-flags" component={FeatureFlags} />
            <Route path="/renovate" render={() => <Redirect to="/steps/3" />} />
            <Redirect to={getRedirectPathByStatus(project?.status)} />
          </Switch>

          <Onboarding />
          <Delighted />
        </Container>
        <StyledBG>
          <BackImg />
          <FooterWrapper>
            <Footer
              color={renofiBlue}
              linkColor={actionRegular}
              maxWidth={760}
            />
          </FooterWrapper>
        </StyledBG>
      </StyledFlex>
    </Page>
  );
};

Routes.propTypes = {
  user: PropTypes.object,
  impersonation: PropTypes.bool,
};

export default Routes;
