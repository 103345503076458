import {useCallback} from 'react';

import {useApolloClient} from '@apollo/client';
import {pathOr} from 'ramda';

import {GET_PROJECT_TASKS} from '@renofi/graphql/src/queries';

import logger from '../../logger';
import noop from '../../noop';

import useChannel from './useChannel';

export default function useDocumentAccepted(projectId, onUpdate = noop) {
  const client = useApolloClient();

  const onDocumentAccepted = useCallback(
    async (event) => {
      logger.debug('DOCUMENT_ACCEPTED', event);
      const facet = pathOr(null, ['task', 'facet'], event);
      if (!projectId || !facet) {
        return;
      }
      const [tasksRsp] = await client.refetchQueries({
        include: [
          {
            query: GET_PROJECT_TASKS,
            variables: {projectId, facet},
          },
        ],
      });
      onUpdate({...tasksRsp, facet});
    },
    [projectId],
  );

  useChannel({
    channelName: projectId && `document_accepted--project=${projectId}`,
    onUpdate: onDocumentAccepted,
  });

  return {};
}
