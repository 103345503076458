import React, {useContext} from 'react';

import {sortBy} from 'lodash';
import PropTypes from 'prop-types';
import lazy from 'lib/asyncComponent';
import {context as ProjectTasksContext} from 'lib/projectTasks';
import {FacetIntro} from 'modules/layout';

import Card from '@renofi/components-internal/src/next/Card';
import {Alert} from '@renofi/components-internal';
import {taskTypes} from '@renofi/utilities/src/enums';
import {useToggledBorrowerProject} from '@renofi/graphql';

import {WizardUploadTask} from '../Task';
import EditTaskButton from '../Eligibility/components/EditTaskButton';
import ExtraDetails from '../Eligibility/components/ExtraDetails';

import SoftCreditTask from './components/SoftCredit/Task';
import {ELIGIBILITY_TASK_TYPES} from './constants';
import {IdentityVerificationWizard} from './components/IdentityVerificationWizard';

const PersonalInfoAndResidencyTask = lazy(
  () =>
    import(
      /* webpackChunkName: "personalInfo-task" */ './components/PersonalInfoAndResidency/Task'
    ),
);

const Borrowers = ({onTaskComplete, showCoBorrowerIcon}) => {
  const {project} = useToggledBorrowerProject();
  const {borrowerInformation = [], eligibility = []} =
    useContext(ProjectTasksContext);

  const softCreditId = borrowerInformation?.find(
    (task) => task.taskType === taskTypes.soft_credit_check,
  )?.id;
  const extraTasks = eligibility.filter(({taskType}) =>
    ELIGIBILITY_TASK_TYPES.includes(taskType),
  );
  const hasTasks = Boolean(borrowerInformation?.length || extraTasks?.length);
  const sortedBorrowerInformation = sortBy(borrowerInformation, (task) =>
    task?.taskType === taskTypes.identity_verification ? -1 : 1,
  );

  return hasTasks ? (
    <Card p={0}>
      <FacetIntro title="Borrower details">
        <Alert>
          Your information will be used only by RenoFi and its lending partners
          as part of your renovation underwriting.
        </Alert>
      </FacetIntro>
      {sortedBorrowerInformation.map((task) => {
        switch (task.taskType) {
          case 'soft_credit_check':
            return <SoftCreditTask key={task.id} {...task} project={project} />;
          case 'identity_verification':
            return (
              <IdentityVerificationWizard
                onTaskComplete={onTaskComplete}
                key={task.id}
                task={task}
              />
            );
          default:
            return null;
        }
      })}
      {extraTasks?.map((task) => {
        switch (task.taskType) {
          case 'personal_info_and_residency':
            return (
              <PersonalInfoAndResidencyTask
                key={task.id}
                showCoBorrowerIcon={showCoBorrowerIcon}
                softCreditId={softCreditId}
                task={task}
              />
            );
          case 'government_issued_id':
            return (
              <WizardUploadTask
                extra={ExtraDetails}
                customAddEditButton={EditTaskButton}
                key={task.id}
                onTaskComplete={onTaskComplete}
                showCoBorrowerIcon={showCoBorrowerIcon}
                showDetails
                showTaskDocuments={false}
                task={task}
              />
            );
          default:
            return null;
        }
      })}
    </Card>
  ) : null;
};

Borrowers.propTypes = {
  onTaskComplete: PropTypes.func,
  showCoBorrowerIcon: PropTypes.bool,
};

export default Borrowers;
