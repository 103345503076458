import React from 'react';

import PropTypes from 'prop-types';
import {pathOr} from 'ramda';

import {EmploymentHistoryDetails} from '@renofi/modules-internal';
import {useToggledBorrowerProject} from '@renofi/graphql';
import {IncomeSourcesDetails} from '@renofi/modules-internal';
import noop from '@renofi/utilities/src/noop';
import {useFeatureFlags} from '@renofi/utilities';

import {
  EMPLOYMENT_HISTORY,
  GOVERNMENT_ID,
  INCOME,
  IDENTITY_VERIFICATION,
} from '../../constants';
import GovIdTaskDetails from '../GovIdTaskDetails';
import {customWizardIdVar} from '../utils';

const ExtraDetails = ({onClickDocument, task}) => {
  const {documents, governmentIssuedIds, status, taskType} = task;
  const {project} = useToggledBorrowerProject();
  const {flags} = useFeatureFlags(['employmentHistoryDocuments']);
  const isEmploymentDocsEnabled = Boolean(flags?.employmentHistoryDocuments);
  const isTaskCompleted = status === 'completed';
  const hasCoBorrower = pathOr(
    false,
    ['borrowersAndPropertyInformation', 'coborrowerIsPresent'],
    project,
  );

  const onClickHistory = () => {
    customWizardIdVar(task.id);
  };

  switch (taskType) {
    case GOVERNMENT_ID:
      return (
        <GovIdTaskDetails
          hasCoBorrower={hasCoBorrower}
          documents={documents}
          govIdDetails={governmentIssuedIds}
          setCurrentDocumentId={onClickDocument}
          completedAt={isTaskCompleted}
        />
      );
    case EMPLOYMENT_HISTORY:
      return (
        <EmploymentHistoryDetails
          onClickDocument={onClickDocument}
          onClick={onClickHistory}
          project={project}
          showDocuments={isEmploymentDocsEnabled}
          task={task}
        />
      );

    case INCOME:
      return (
        <IncomeSourcesDetails
          onClickDocument={onClickDocument}
          project={project}
          task={task}
        />
      );
    case IDENTITY_VERIFICATION:
      return <p>dewd</p>;
    default:
      return null;
  }
};

ExtraDetails.propTypes = {
  onClickDocument: PropTypes.func,
  task: PropTypes.shape({
    documents: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      }),
    ),
    employmentHistories: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      }),
    ),
    governmentIssuedIds: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      }),
    ),
    id: PropTypes.string,
    status: PropTypes.string,
    taskType: PropTypes.string,
  }),
};

ExtraDetails.defaultProps = {
  onClickDocument: noop,
};

export default ExtraDetails;
