import {pathOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';

import {MARK_TASK_DATA_READY_FOR_PROCESSING} from '../mutations';

import useMutation from './useMutation';

export default function useMarkTaskDataAsReadyForProcessing({
  onCompleted = noop,
  refetchQueries = [],
} = {}) {
  const [markTaskDataAsReadyForProcessing, {data, loading, error}] =
    useMutation(MARK_TASK_DATA_READY_FOR_PROCESSING, {
      onCompleted,
      refetchQueries,
    });
  const response = pathOr(null, ['task'], data);

  return {markTaskDataAsReadyForProcessing, response, loading, error};
}
