import React, {useEffect, useState} from 'react';

import {isNil, pathOr} from 'ramda';
import PropTypes from 'prop-types';
import {useHistory, useParams} from 'react-router-dom';
import useDeepCompareEffect from 'use-deep-compare-effect';
import {useCurrentTask} from 'modules/project/hooks';

import Wizard, {useWizardState} from '@renofi/components-internal/src/Wizard';
import {
  useUpdateRealEstateOwned,
  useMarkTaskDataAsReadyForProcessing,
  useRemoveRealEstateOwnedProperty,
} from '@renofi/graphql/src';
import isNumber from '@renofi/utilities/src/isNumber';

import initialSteps from './steps';
import {initialFormData} from './utils';
import validateAll from './validation';
import AdditionalProperties from './AdditionalProperties';
import SubjectProperty from './SubjectProperty/SubjectProperty';
import SoldProperties from './SoldProperties';

const RealEstateWizard = ({task, project}) => {
  const history = useHistory();
  const {step, propertyId, type} = useParams();
  const [formData, setFormData] = useState(initialFormData(task));
  const {borrowersAndPropertyInformation: borrowersInfo} = project || {};
  const {
    additionalProperties,
    additionalPropertyNumber,
    soldProperties,
    soldPropertyNumber,
  } = formData;
  const [subjectPropertyId, setSubjectPropertyId] = useState(
    pathOr(null, ['realEstateOwned', 'subjectProperty', 'id'], task),
  );
  const [status, setStatus] = useState('idle');
  const {sendEvent} = useCurrentTask({taskId: task?.id});

  const {updateRealEstateOwned} = useUpdateRealEstateOwned();
  const {markTaskDataAsReadyForProcessing} =
    useMarkTaskDataAsReadyForProcessing();
  const {removeRealEstateOwnedProperty} = useRemoveRealEstateOwnedProperty();

  const currentRoute = `${step}${propertyId ? '/' + propertyId : ''}${
    type ? '/' + type : ''
  }`;

  const {
    steps,
    next,
    prev,
    toggleStep,
    addStep,
    removeStep,
    findStepById,
    setComplete,
    updateStep,
  } = useWizardState(initialSteps, currentRoute);
  const currentStep = findStepById(currentRoute, steps);
  const allStepsCompleted = steps.every((step) => step.complete);

  useEffect(() => setStatus('idle'), [currentRoute]);

  useDeepCompareEffect(() => {
    validateAll(steps, formData, setComplete, task);
  }, [steps, task.documents?.length, formData]);

  useEffect(() => {
    (async () => {
      if (status === 'completed') {
        onNext(next);
        setStatus('idle');
      }
    })();
  }, [next, status]);

  function getBorrowersText() {
    const coborrowerName = borrowersInfo?.coborrowerFirstName || '';
    const coborrowerText = coborrowerName ? ` or ${coborrowerName}` : '';

    return `${borrowersInfo?.firstName}${coborrowerText}`;
  }

  function onClose() {
    history.push('/steps/1/borrower_information');
  }

  function onCloseWizard() {
    sendEvent('Secure/Task-Closed');

    onClose();
  }

  function onPrev() {
    history.push(`/borrower_information/real-estate-wizard/${prev}`);
  }

  function onNext(next) {
    history.push(`/borrower_information/real-estate-wizard/${next}`);
  }

  function onSelectStep(route) {
    const step = findStepById(route, steps);
    if (step.complete) {
      history.push(`/borrower_information/real-estate-wizard/${route}`);
    }
  }

  function onChange(key, value) {
    setStatus('dirty');
    setFormData((data) => ({...data, [key]: value}));
  }

  async function onRemove(route) {
    removeStep(route);
    const index = findIndex(route);
    const isAdditionalProperty = route.includes('additional');
    if (isAdditionalProperty) {
      removeAdditionalProperty(index);
    } else {
      removeSoldProperty(index);
    }
    const property = isAdditionalProperty
      ? additionalProperties[index]
      : soldProperties[index];

    if (isNil(property)) return;

    await removeRealEstateOwnedProperty({variables: {id: property.id}});
  }

  function removeAdditionalProperty(index) {
    onChange(
      'additionalProperties',
      additionalProperties.filter((data, i) => i !== index),
    );
    onChange('additionalPropertyNumber', additionalPropertyNumber - 1);
  }

  function removeSoldProperty(index) {
    onChange(
      'soldProperties',
      soldProperties.filter((data, i) => i !== index),
    );
    onChange('soldPropertyNumber', soldPropertyNumber - 1);
  }

  function findIndex(route) {
    const id = route.split('/')[1];
    return id - 2;
  }

  async function onFinalise() {
    await markTaskDataAsReadyForProcessing({variables: {taskId: task.id}});
    sendEvent('Secure/Task-Finished');
  }

  async function onSubmit() {
    if (status === 'idle') {
      return onNext(next);
    }

    setStatus('loading');
    const {data} = await updateRealEstateOwned({
      variables: {taskId: task.id, ...formData},
    });
    setStatus('updated');
    setSubjectPropertyId(
      data?.updateRealEstateOwned?.realEstateOwned?.subjectProperty?.id,
    );

    if (data?.updateRealEstateOwned?.realEstateOwned) {
      setFormData(
        initialFormData({
          realEstateOwned: data?.updateRealEstateOwned?.realEstateOwned,
        }),
      );
    }

    setTimeout(() => setStatus('completed'));
  }

  function isLastPropertyStep() {
    const current = isNumber(propertyId) ? `${step}/${propertyId}` : step;
    return (
      !isNil(propertyId) && (next === currentRoute || !next.includes(current))
    );
  }

  function isFinalStep() {
    return (
      step === 'sold' &&
      ((isLastPropertyStep() &&
        Number(propertyId) >= formData.soldPropertyNumber) ||
        formData.soldPropertyNumber === 0)
    );
  }

  const borrowersText = getBorrowersText();

  return (
    <Wizard
      show
      width={820}
      disabled={isFinalStep() ? !allStepsCompleted : !currentStep.complete}
      loading={status === 'loading'}
      final={isFinalStep()}
      finalButtonVariant="primary"
      finalLabel="I’m done with this task"
      start={!currentRoute}
      steps={steps}
      header="Real estate owned"
      nextLabel={isLastPropertyStep() ? 'I’m done with this property' : 'Next'}
      onFinish={async () => {
        await onSubmit();
        await onFinalise();
        onClose();
      }}
      onRemoveStep={onRemove}
      onNext={onSubmit}
      onPrev={() => onPrev()}
      onStart={() => {}}
      onClose={onCloseWizard}
      onSelectStep={(step) => onSelectStep(step)}>
      <SubjectProperty
        names={borrowersText}
        status={status}
        toggleStep={toggleStep}
        updateStep={updateStep}
        onChange={onChange}
        formData={formData}
        task={task}
        subjectPropertyId={subjectPropertyId}
      />
      <AdditionalProperties
        names={borrowersText}
        status={status}
        toggleStep={toggleStep}
        addStep={addStep}
        updateStep={updateStep}
        removeStep={removeStep}
        onChange={onChange}
        formData={formData}
        task={task}
        borrowersInfo={borrowersInfo}
      />
      <SoldProperties
        names={borrowersText}
        formData={formData}
        onChange={onChange}
        removeStep={removeStep}
        addStep={addStep}
        updateStep={updateStep}
        status={status}
        borrowersInfo={borrowersInfo}
      />
    </Wizard>
  );
};

RealEstateWizard.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.string,
    taskType: PropTypes.string,
    documents: PropTypes.array,
    realEstateOwned: PropTypes.shape({
      subjectProperty: PropTypes.shape({
        id: PropTypes.string,
      }),
    }),
  }),
  project: PropTypes.shape({
    borrower: PropTypes.shape({
      firstName: PropTypes.string,
    }),
    borrowersAndPropertyInformation: PropTypes.shape({
      coborrowerIsPresent: PropTypes.bool,
      coborrowerFirstName: PropTypes.string,
      coborrowerLastName: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
  }),
};

export default RealEstateWizard;
