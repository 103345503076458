import {pathOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';

import useMutation from '../../useMutation';
import {MARK_TASK_FINISHED_BY_BORROWER} from '../../../mutations';

export default function useMarkTaskFinishedByBorrower({
  onCompleted = noop,
  refetchQueries = [],
} = {}) {
  const [markTaskFinishedByBorrower, {data, loading, error}] = useMutation(
    MARK_TASK_FINISHED_BY_BORROWER,
    {
      onCompleted,
      refetchQueries,
    },
  );
  const response = pathOr(null, ['markTaskFinishedByBorrower'], data);
  return {markTaskFinishedByBorrower, response, loading, error};
}
