/* eslint-disable max-lines */
import React, {useState, useMemo} from 'react';

import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import {omit, pathOr, last} from 'ramda';
import {useCurrentTask} from 'modules/project/hooks';

import {ConfirmCloseTaskModal, Wizard} from '@renofi/components-internal';
import {useNotifications} from '@renofi/utilities/src/hooks';
import noop from '@renofi/utilities/src/noop';
import humanizeSnakeCase from '@renofi/utilities/src/humanizeSnakeCase';
import {GET_PROJECT_TASKS} from '@renofi/graphql';
import {
  useMarkTaskFinishedByBorrower,
  useMarkTaskAsOutstanding,
  useSubmitAssetDetail,
} from '@renofi/graphql/src/hooks';

import {getBorrowerSteps, getCoBorrowerSteps} from './steps';
import {initialFormData, generateAssets} from './utils';
import BorrowerSteps from './BorrowerSteps/BorrowerSteps';
import CoBorrowerSteps from './CoBorrowerSteps/CoBorrowerSteps';

const OMIT_PROPS = ['__typename', 'taskId', 'isNew', 'isDirty', 'id'];

const AssetsWizard = ({
  task,
  project,
  hasCoBorrower,
  dirty,
  onChange,
  onFinish,
}) => {
  const {id: taskId} = task;
  const {sendEvent} = useCurrentTask({taskId});

  const [loading, setLoading] = useState(false);
  const [confirmClose, setConfirmClose] = useState(false);

  const history = useHistory();
  const {addNotification} = useNotifications();
  const {submitAssetDetail} = useSubmitAssetDetail();

  const [stepId, setStepId] = useState('primary-borrower/assets');
  const [formData, setFormData] = useState(initialFormData(task));

  const [borrowerAssets, setBorrowerAssets] = useState(
    generateAssets(task, 'borrower'),
  );
  const [coBorrowerAssets, setCoBorrowerAssets] = useState(
    generateAssets(task, 'coborrower'),
  );

  const refetchQueries = [
    {
      query: GET_PROJECT_TASKS,
      variables: {projectId: project.id, facet: 'eligibility'},
    },
  ];

  const {markTaskAsOutstanding} = useMarkTaskAsOutstanding({
    refetchQueries,
  });
  const {markTaskFinishedByBorrower} = useMarkTaskFinishedByBorrower({
    refetchQueries,
  });

  const {
    id: projectId,
    borrower,
    borrowersAndPropertyInformation: borrowersInfo,
  } = project || {};

  const {documents: taskDocuments} = task || {};

  const steps = useMemo(() => {
    const borrowerSteps = getBorrowerSteps({
      borrower,
      stepId,
      assets: borrowerAssets,
      formData,
      taskDocuments,
    });

    const coBorrowerSteps = getCoBorrowerSteps({
      borrowersInfo,
      stepId,
      assets: coBorrowerAssets,
      formData,
      taskDocuments,
      hasCoBorrower,
    });

    return [borrowerSteps, coBorrowerSteps];
  }, [
    borrower,
    borrowersInfo,
    stepId,
    borrowerAssets,
    coBorrowerAssets,
    formData,
    taskDocuments,
    hasCoBorrower,
  ]);

  const flattenSteps = useMemo(() => {
    return getFlattenSteps();
  }, [steps]);

  const step = useMemo(() => {
    return flattenSteps.find(({id}) => id === stepId);
  }, [flattenSteps, stepId]);

  const isLastStep = useMemo(() => {
    const lastId = last(flattenSteps)?.id;

    return !step.id.includes('assets') && step.id === lastId;
  }, [flattenSteps, step]);

  function getFlattenSteps() {
    const result = steps.reduce((memo, item) => {
      const filtered = item.children.filter(({hidden}) => !hidden);
      if (!item.hidden) {
        memo.push(item);
      }

      memo.push(...filtered);

      return memo;
    }, []);

    return result;
  }

  function onClose() {
    history.push('/steps/1/borrower_information');
  }

  async function onSubmit(callback = noop) {
    try {
      const filtered = formData.filter((assetDetail) => {
        return (
          assetDetail.assetType === step.assetType &&
          assetDetail.borrowerRole === step.borrowerRole &&
          (assetDetail.isNew || assetDetail.isDirty)
        );
      });

      setLoading(true);

      const submittedItems = await Promise.all(
        filtered.map(async (assetDetail) => {
          const response = await submitAssetDetail({
            variables: {
              attributes: omit(OMIT_PROPS, assetDetail),
              id: assetDetail.id,
              taskId: task.id,
            },
          });

          return pathOr(
            null,
            ['data', 'submitAssetDetail', 'assetDetail'],
            response,
          );
        }),
      );

      if (step.borrowerRole === 'borrower') {
        preCheckCoBorrowerAssets(filtered);
      }

      const newFormData = formData.reduce((memo, assetDetail) => {
        const newAsset = submittedItems.find((s) => s.id === assetDetail.id);
        const updatedAsset = newAsset
          ? {...newAsset, isNew: false, isDirty: false}
          : assetDetail;

        memo.push(updatedAsset);

        return memo;
      }, []);

      setFormData(newFormData);

      callback();
    } catch (error) {
      addNotification({
        variant: 'danger',
        content: 'Failed to submit asset details',
        type: 'snackbar',
      });
    } finally {
      setLoading(false);
    }
  }

  function goToPrevStep() {
    const currentIdx = flattenSteps.findIndex(({id}) => id === step.id);
    const prev = flattenSteps[currentIdx - 1];

    if (prev) {
      setStepId(prev.id);
    }
  }

  function goToNextStep() {
    const currentIdx = flattenSteps.findIndex(({id}) => id === step.id);
    const next = flattenSteps[currentIdx + 1];

    if (next) {
      setStepId(next.id);
      return;
    }

    onFinishWizard();
  }

  function preCheckCoBorrowerAssets(assets) {
    assets.forEach(({borrowerRole, jointAccount, assetType}) => {
      if (borrowerRole === 'borrower' && jointAccount) {
        setCoBorrowerAssets({
          ...coBorrowerAssets,
          [assetType]: {
            ...coBorrowerAssets[assetType],
            checked: true,
          },
        });
      }
    });
  }

  async function onPrev() {
    goToPrevStep();
  }

  function shouldSkipSubmit() {
    return stepId.includes('statements') || stepId.includes('assets');
  }

  async function onNext() {
    if (shouldSkipSubmit()) {
      goToNextStep();
      return;
    }

    await onSubmit(goToNextStep);
  }

  async function onSelectStep(id) {
    if (!step.complete) {
      addNotification({
        variant: 'info',
        content: 'Please complete the current step',
        type: 'snackbar',
      });
      return;
    }

    const newId = id === 'primary-borrower' ? `${id}/assets` : id;

    if (shouldSkipSubmit()) {
      setStepId(newId);
      return;
    }

    await onSubmit(() => setStepId(newId));
  }

  function onAccountChange(id, key, value) {
    onChange();

    const newAccounts = formData.map((account) => {
      if (id === account.id) {
        return {
          ...account,
          [key]: value,
          isDirty: true,
        };
      }

      return account;
    });

    setFormData(newAccounts);
  }

  function goToCoBorrowerAssets() {
    setStepId('co-borrower/assets');
  }

  function getNextLabel() {
    if (isLastStep) {
      return 'I’m done with this task';
    }

    if (stepId.includes('statements')) {
      return `Done with ${humanizeSnakeCase(step.assetType)} assets`;
    }

    return 'Next';
  }

  function onCloseWizard() {
    if (dirty) {
      setConfirmClose(true);
      return;
    }
    sendEvent('Secure/Task-Closed');
    onClose();
  }

  async function onConfirmClose() {
    sendEvent('Secure/Task-ConfirmClosed');
    setLoading(true);
    await markTaskAsOutstanding({variables: {taskId}});
    setLoading(false);
    onClose();
  }

  async function onFinishWizard() {
    setLoading(true);
    await markTaskFinishedByBorrower({variables: {taskId}});
    setLoading(false);
    onFinish();
    onClose();

    addNotification({
      variant: 'success',
      content: 'Assets added successfully',
      type: 'snackbar',
    });
  }

  if (!project) {
    return;
  }

  const isLastStepDisabled =
    isLastStep &&
    flattenSteps.filter(({isRoot}) => !isRoot).some(({complete}) => !complete);
  const disabled = !step.complete || isLastStepDisabled;

  return (
    <>
      <Wizard
        show
        disableEscape
        final={false}
        disabled={disabled}
        loading={loading}
        finalButtonVariant="primary"
        finalLabel="I’m done with this task"
        start={false}
        hidePrev={stepId === 'primary-borrower/assets'}
        steps={steps}
        header="Assets"
        showNext={step.id !== 'co-borrower'}
        nextLabel={getNextLabel()}
        onFinish={() => {}}
        onNext={onNext}
        onPrev={onPrev}
        onStart={() => {}}
        onClose={onCloseWizard}
        onSelectStep={onSelectStep}>
        <BorrowerSteps
          step={step}
          steps={steps[0].children}
          borrower={borrower}
          assets={borrowerAssets}
          formData={formData}
          task={task}
          onAccountChange={onAccountChange}
          borrowerRole="borrower"
          setFormData={setFormData}
          hasCoBorrower={hasCoBorrower}
          setAssets={setBorrowerAssets}
          setCoBorrowerAssets={setCoBorrowerAssets}
          setLoading={setLoading}
          projectId={projectId}
          onChange={onChange}
        />
        <CoBorrowerSteps
          step={step}
          borrower={borrower}
          coBorrowerName={borrowersInfo?.coborrowerFirstName || ''}
          assets={coBorrowerAssets}
          formData={formData}
          task={task}
          onAccountChange={onAccountChange}
          borrowerRole="coborrower"
          setFormData={setFormData}
          hasCoBorrower={hasCoBorrower}
          goToCoBorrowerAssets={goToCoBorrowerAssets}
          setAssets={setCoBorrowerAssets}
          setLoading={setLoading}
          projectId={projectId}
          onChange={onChange}
        />
      </Wizard>
      <ConfirmCloseTaskModal
        loading={loading}
        onCancelConfirm={() => setConfirmClose(false)}
        onConfirm={onConfirmClose}
        show={confirmClose}
      />
    </>
  );
};

AssetsWizard.propTypes = {
  task: PropTypes.object,
  project: PropTypes.object,
  hasCoBorrower: PropTypes.bool,
  dirty: PropTypes.bool,
  onChange: PropTypes.func,
  onFinish: PropTypes.func,
};

export default AssetsWizard;
