import {useMemo} from 'react';

import {find, head, pipe, propEq, propOr} from 'ramda';

import {useCookies} from '@renofi/utilities/src/cookies';
import {UUID_NAME_REGEX} from '@renofi/utilities/src/react/validateUuidProp';

import useBorrowerProjects from './useBorrowerProjects';

const sortByCreatedDate = (projects = []) =>
  projects.toSorted((left, right) => {
    return new Date(right?.createdAt) - new Date(left?.createdAt);
  });

export default function useToggledBorrowerProject() {
  const {cookies} = useCookies(['pid']);
  const {fetch, projects, loading} = useBorrowerProjects();

  const project = useMemo(() => {
    // pid could be a string "undefined", so make sure it's definitely a UUID.
    const pid = propOr(null, 'pid', cookies);
    const projectId = UUID_NAME_REGEX.test(pid) ? pid : null;

    return projectId
      ? find(propEq('id', projectId), projects)
      : pipe(sortByCreatedDate, head)(projects);
  }, [JSON.stringify({cookies, projects})]);

  return {
    project: project,
    fetch,
    loading,
  };
}
