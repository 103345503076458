import React, {useEffect} from 'react';

import {useHistory, useLocation} from 'react-router';
import PropTypes from 'prop-types';
import {FlagProvider} from '@unleash/proxy-client-react';

import {pageView} from '@renofi/utilities/src/analytics';
import useReturnsAfterOneMonthAnalytics from '@renofi/utilities/src/hooks/useReturnsAfterOneMonthAnalytics';

import {
  context as ProjectTasksContext,
  useAllProjectTasks,
} from '../../../../lib/projectTasks';
import Routes from '../Routes';

const Root = ({user}) => {
  useReturnsAfterOneMonthAnalytics(user);
  const value = useAllProjectTasks();

  const history = useHistory();
  const location = useLocation();

  const unleashConfig = {
    url: process.env.REACT_APP_UNLEASH_PROXY_URL,
    clientKey: process.env.REACT_APP_UNLEASH_CLIENT_KEY,
    refreshInterval: 15,
    appName: `secure-app-${process.env.REACT_APP_ENV}`,
    environment: process.env.REACT_APP_ENV,
  };

  useEffect(() => {
    const {pathname} = location;
    pageView(pathname, {path: pathname});
    history.listen(async ({pathname: path}) => {
      pageView(path, {path});
    });
  }, []);

  return (
    <ProjectTasksContext.Provider value={value}>
      <FlagProvider config={unleashConfig}>
        <Routes />
      </FlagProvider>
    </ProjectTasksContext.Provider>
  );
};

Root.propTypes = {
  identityHash: PropTypes.string,
  config: PropTypes.object,
  user: PropTypes.object,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

export default Root;
