import React, {Fragment} from 'react';

import PropTypes from 'prop-types';
import {isNil, pipe, propOr} from 'ramda';
import getYesNoByBool from 'lib/getYesNoByBool';
import {useCurrentTask} from 'modules/project';
import {ManageDocuments} from 'modules/tasks-wizard';

import {Flex} from '@renofi/components-internal';
import capitalize from '@renofi/utilities/src/capitalize';
import formatMoney from '@renofi/utilities/src/formatMoney';

import {MONTH_OPTIONS} from '../../constants';

import {Container, Label, Value} from './styled';

const getMonth = (date) => MONTH_OPTIONS[new Date(date).getMonth()];

const getBoolValue = pipe(getYesNoByBool, capitalize);

const PROPS_TO_DISPLAY = [
  ['occurredRecently', 'Purchase or refinance:', getBoolValue],
  ['date', 'Month of appraisal:', getMonth],
  ['value', 'Appraisal value:', formatMoney],
  ['haveAppraisalFile', 'Appraisal file:', getBoolValue],
];

const RecentTransactionsTask = ({taskId}) => {
  const {isProcessing, isCompleted, task} = useCurrentTask({
    facet: 'appraisal',
    taskId,
  });
  const transactions = propOr(null, 'appraisalRecentTransactions', task);
  const haveAppraisalFile = propOr(null, 'haveAppraisalFile', transactions);

  return (
    <Fragment>
      {(isProcessing || isCompleted) && (
        <Fragment>
          <Container>
            <Flex>
              {PROPS_TO_DISPLAY.map(([key, label, getDisplayValue], index) => {
                const value = propOr(null, key, transactions);
                const isNotLast = index !== PROPS_TO_DISPLAY.length - 1;
                return (
                  <Flex key={key} mr={1}>
                    <Label mr={1}>{label}</Label>
                    <Value>
                      {!isNil(value) ? getDisplayValue(value) : '—'}
                      {isNotLast && ','}
                    </Value>
                  </Flex>
                );
              })}
            </Flex>
          </Container>

          {haveAppraisalFile && (
            <Flex mt={3} width={0.5}>
              <ManageDocuments immutable taskId={taskId} />
            </Flex>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

RecentTransactionsTask.propTypes = {
  taskId: PropTypes.string.isRequired,
};

export default RecentTransactionsTask;
