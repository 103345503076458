import React, {Fragment} from 'react';

import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import {pathOr} from 'ramda';
import {CompletedTaskDocumentsAlert} from 'modules/project/components/Task';

import {sendEvent} from '@renofi/utilities/src/analytics/utils';
import {
  Card,
  Flex,
  Icon,
  Link,
  Switch,
  TaskStatus,
  Text,
} from '@renofi/components-internal';
import {
  actionRegular,
  basic15,
  basic55,
  basic75,
  basic80,
  dangerRegular,
} from '@renofi/theme';
import {validateUuidProp} from '@renofi/utilities/src/react';
import {
  useToggleContractorsReadyForContact,
  useToggledBorrowerProject,
} from '@renofi/graphql';
import {useFeatureFlags, useNotifications} from '@renofi/utilities/src/hooks';

import {NEW_CONTRACTOR} from '../../constants';
import {hasMissingFields} from '../../utils';

import {
  Action,
  DesktopButton,
  MobileButton,
  SwipeRow,
  TableWrapper,
  Table,
  Td,
  Th,
  Tr,
  Wrapper,
  Email,
} from './styled';
import ContractorStatus from './ContractorStatus';

const NEW_PATH = `/steps/1/contractor/${NEW_CONTRACTOR}`;

const isConsentEnabled = pathOr(false, ['contractRequests', 'enabled']);

const ContractorsTable = ({contractors, onClickDelete, task}) => {
  const {project} = useToggledBorrowerProject();
  const projectId = project?.id;
  const consentGivenAt = project?.timeline?.contractorsReadyForContactAt;
  const {flags} = useFeatureFlags();
  const consentToggleEnabled = isConsentEnabled(flags);
  const {addNotification} = useNotifications();
  const {loading, toggleContractorsReadyForContact} =
    useToggleContractorsReadyForContact();
  const history = useHistory();
  const isCompleted = task?.status === 'completed';
  const isPersonalLoan = project?.loanTypeEnum?.isPersonal;

  const onClickEdit = (contractorId) => {
    history.push(`/steps/1/contractor/${contractorId}`);
  };

  const onClickConsent = async (evt, ready) => {
    const rsp = await toggleContractorsReadyForContact({
      variables: {projectId, ready},
    });
    const success = Boolean(
      rsp?.data?.toggleContractorsReadyForContact?.success,
    );

    let content = '';
    if (!success) {
      content = 'There was a problem with this request; please try again.';
    } else {
      content = ready
        ? 'Renovation document request confirmed.'
        : 'Renovation document request withdrawn.';
      sendEvent('Secure/Toggle-Source-Docs-From-Contractor', {
        loanType: project?.loanType,
        taskId: task?.id,
        consent: ready,
      });
    }

    addNotification({
      variant: success ? 'success' : 'danger',
      content,
      type: 'snackbar',
    });
  };

  return (
    <Wrapper>
      <TaskStatus status={task?.status} />

      <Text my={3} fontSize={18} color={basic80}>
        {task?.title}
      </Text>

      {!isCompleted ? (
        <MobileButton block medium onClick={() => history.push(NEW_PATH)}>
          Add contractor
        </MobileButton>
      ) : (
        ' '
      )}

      {consentToggleEnabled && Boolean(contractors?.length) ? (
        <Fragment>
          <Text color={basic55}>
            {isPersonalLoan
              ? 'Connecting your contractor(s) with RenoFi will help streamline the communication of any documentation follow ups. They will be invited to login to a separate portal where they can upload estimates or invoices for us to review. You may also upload any of these documents into the renovation task yourself.'
              : 'Make sure to inform all contractors that RenoFi will be reaching out for additional details, plans, estimates, and contracts to avoid any delays with the review process. You may also upload any of these documents into the renovation tasks yourself.'}
          </Text>
          <Card my={24} defaultBorderColor={basic15} radius={8}>
            <Flex>
              <Switch
                css={{minWidth: 40}}
                checked={Boolean(consentGivenAt)}
                disabled={isCompleted || loading}
                onClick={onClickConsent}
              />
              <Text ml={3} color={basic75}>
                {isPersonalLoan
                  ? 'I wish to invite my contractor to work with RenoFi directly.'
                  : 'I have informed my contractor(s) and I am ready for RenoFi to email them.'}
              </Text>
            </Flex>
          </Card>
        </Fragment>
      ) : null}

      <TableWrapper>
        <Table>
          <thead>
            <tr>
              <Th>Business name</Th>
              <Th>Contact name</Th>
              <Th>Phone</Th>
              <Th>Email</Th>
              {consentToggleEnabled ? <Th>Status</Th> : null}
              <Th>&nbsp;</Th>
              <Th width={190}>
                {!isCompleted ? (
                  <DesktopButton onClick={() => history.push(NEW_PATH)}>
                    Add contractor
                  </DesktopButton>
                ) : (
                  ' '
                )}
              </Th>
            </tr>
          </thead>
          <tbody>
            <SwipeRow>
              <Td colSpan={6} textAlign="left">
                <Flex
                  alignItems="center"
                  width="calc(100vw - 48px)"
                  justifyContent="center"
                  css={{gap: 16}}>
                  <Text fontSize={14}>Swipe to view table contents</Text>
                  <Icon name="swipe" color="#1C1B1F" />
                </Flex>
              </Td>
            </SwipeRow>
            {contractors.map((contractor) => {
              const {
                businessName,
                contactName,
                ddRequestedAt,
                deletedAt,
                email,
                id,
                phoneNumber,
              } = contractor;
              // we can assume email/phone are valid as they're already submitted
              const invalidFields = hasMissingFields({
                contractor,
                isEmailValid: true,
                isPhoneNumberValid: true,
              });
              const unfinished = Boolean(invalidFields?.length);
              const isDeleted = Boolean(deletedAt);
              const isDdRequested = Boolean(ddRequestedAt);

              return (
                <Tr key={id} isDeleted={isDeleted}>
                  <Td>
                    <Link onClick={() => onClickEdit(id)}>{businessName}</Link>
                  </Td>
                  <Td>{contactName}</Td>
                  <Td>{phoneNumber}</Td>
                  <Td>
                    <Email title={email}>{email}</Email>
                  </Td>
                  {consentToggleEnabled ? (
                    <Td>
                      <ContractorStatus contractor={contractor} />
                    </Td>
                  ) : null}
                  <Td>
                    {unfinished ? (
                      <Flex>
                        <Icon color={dangerRegular} name="info" />
                        <Text color={dangerRegular} ml={2}>
                          Missing contractor details
                        </Text>
                      </Flex>
                    ) : (
                      ''
                    )}
                  </Td>
                  <Td css={{textAlign: 'right'}}>
                    {!isCompleted && !isDdRequested && !isDeleted ? (
                      <Fragment>
                        <Action onClick={() => onClickEdit(id)}>
                          <Icon name="pencil" color={actionRegular} />
                          <Text>Edit</Text>
                        </Action>
                        <Action onClick={() => onClickDelete(contractor)}>
                          <Icon name="trash" color={actionRegular} />
                          <Text>Remove</Text>
                        </Action>
                      </Fragment>
                    ) : (
                      ' '
                    )}
                  </Td>
                </Tr>
              );
            })}

            {!contractors.length && (
              <Tr>
                <Td colSpan={6}>
                  <Flex justifyContent="center" py={4}>
                    <Link onClick={() => history.push(NEW_PATH)}>
                      You must add all contractors that you’ve chosen to
                      complete your renovation project.
                    </Link>
                  </Flex>
                </Td>
              </Tr>
            )}
          </tbody>
        </Table>
      </TableWrapper>

      {isCompleted ? (
        <CompletedTaskDocumentsAlert taskTitle={task?.title} />
      ) : null}
    </Wrapper>
  );
};

ContractorsTable.propTypes = {
  contractors: PropTypes.arrayOf(
    PropTypes.shape({
      id: validateUuidProp,
      businessName: PropTypes.string,
      contactName: PropTypes.string,
      email: PropTypes.string,
      phoneNumber: PropTypes.string,
    }),
  ),
  onClickDelete: PropTypes.func,
  task: PropTypes.shape({
    id: validateUuidProp,
    status: PropTypes.string,
    title: PropTypes.string,
  }),
};

export default ContractorsTable;
