import {Box} from '@renofi/components-internal';
import {hasCoBorrowerInProject} from '@renofi/utilities/src/coborrower';
import noop from '@renofi/utilities/src/noop';

import BorrowerEmploymentHistory from './BorrowerEmploymentHistory';
import {getHistory} from './utils';

const EmploymentHistoryDetails = ({
  areActionsDisabled = true,
  internal = false,
  onAccept = noop,
  onClickDocument = noop,
  onClick = noop,
  onReject = noop,
  project,
  showDocuments = true,
  task,
}) => {
  const borrowerName = project?.primaryApplicant?.fullName;

  const {employmentHistories = []} = task;
  const hasCoBorrower = hasCoBorrowerInProject(project);
  const borrowerHistory = employmentHistories.filter(getHistory(false));
  const commonProps = {
    areActionsDisabled,
    internal,
    onAccept,
    onClick,
    onClickDocument,
    onReject,
    showDocuments,
  };

  return (
    <Box flex={1}>
      <BorrowerEmploymentHistory
        borrowerName={borrowerName}
        borrowerRole="borrower"
        employmentHistory={borrowerHistory}
        {...commonProps}
      />

      {hasCoBorrower ? (
        <BorrowerEmploymentHistory
          borrowerName={project?.coborrower?.fullName}
          borrowerRole="coborrower"
          employmentHistory={employmentHistories.filter(getHistory(true))}
          {...commonProps}
        />
      ) : null}
    </Box>
  );
};

export default EmploymentHistoryDetails;
