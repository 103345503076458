import React, {useEffect} from 'react';

import PropTypes from 'prop-types';

import {isMobile} from '@renofi/theme/src/breakpoints';
import {sendEvent} from '@renofi/utilities/src/analytics/utils';

import {STEP_MAPPER} from '../constants';

import BaseContent from './BaseContent';
import {getPropertyDetails} from './utils';

const FilledAddress = ({info, setStep}) => {
  const details = getPropertyDetails(info);

  const onAccept = () => {
    sendEvent('Secure/Onboarding-Property-Confirmation-Selected');
    setStep(STEP_MAPPER.PropertyTrust);
  };

  const onDecline = () => {
    sendEvent('Secure/Onboarding-Property-Confirmation-Selected');
    setStep(STEP_MAPPER.Address);
  };

  useEffect(() => {
    sendEvent('Secure/Onboarding-Property-Confirmation-Presented');
  }, []);

  return (
    <BaseContent
      mainContent={'Is the renovation taking place here?'}
      sideContent={details}
      sideContentCss={{textAlign: isMobile() ? 'center' : 'left'}}
      onAccept={onAccept}
      onDecline={onDecline}
    />
  );
};

FilledAddress.propTypes = {
  info: PropTypes.shape({
    propertyCity: PropTypes.string,
    propertyState: PropTypes.string,
    propertyStreetAddressOne: PropTypes.string,
    propertyStreetAddressTwo: PropTypes.string,
    propertyZipCode: PropTypes.string,
  }),
  setStep: PropTypes.func,
  update: PropTypes.func,
};

export default FilledAddress;
