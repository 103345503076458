import {isNil, pick} from 'ramda';

export function initialFormData(task) {
  const {realEstateOwned} = task;

  if (isNil(realEstateOwned)) return {};

  const {
    subjectProperty = {},
    additionalProperties = [],
    soldProperties = [],
  } = realEstateOwned;

  return {
    ...realEstateOwned,
    subjectProperty: pick(
      [
        'existingMortgageLoan',
        'taxAndInsuranceIncluded',
        'homeownersAssociationFeesExist',
        'homeownersAssociationFeesIncluded',
      ],
      subjectProperty || {},
    ),
    additionalProperties: additionalProperties?.map((property) => ({
      id: property.id,
      city: property.city,
      state: property.state,
      zipCode: property.zipCode,
      county: property.county,
      streetAddressTwo: property.streetAddressTwo,
      streetAddressOne: property.streetAddressOne,
      propertyType: property.propertyType,
      currentOccupancy: property.currentOccupancy,
      currentOccupancyOther: property.currentOccupancyOther,
      intendedOccupancy: property.intendedOccupancy,
      propertyStatus: property.propertyStatus,
      yearPurchased: property.yearPurchased,
      marketValue: property.marketValue,
      rentalIncome: property.rentalIncome,
      existingMortgageLoan: property.existingMortgageLoan,
      taxAndInsuranceIncluded: property.taxAndInsuranceIncluded,
      homeownersAssociationFeesIncluded:
        property.homeownersAssociationFeesIncluded,
      homeownersAssociationFeesExist: property.homeownersAssociationFeesExist,
      coborrowerOwned: property.coborrowerOwned,
      primaryBorrowerOwned: property.primaryBorrowerOwned,
    })),
    soldProperties: soldProperties?.map((property) => ({
      id: property.id,
      city: property.city,
      state: property.state,
      zipCode: property.zipCode,
      county: property.county,
      streetAddressTwo: property.streetAddressTwo,
      streetAddressOne: property.streetAddressOne,
      propertyType: property.propertyType,
      primaryBorrowerOwned: property.primaryBorrowerOwned,
      coborrowerOwned: property.coborrowerOwned,
      soldDate: property?.soldDate,
    })),
  };
}

export function findPropertyDocuments(documentIds, task) {
  const statementFiles = findDocuments('mortgage_statement');
  const taxFiles = findDocuments('property_tax');
  const policyFiles = findDocuments('homowners_insurance_policy');
  const feesFiles = findDocuments('homeowners_association_fees');
  const documents = [
    ...statementFiles,
    ...taxFiles,
    ...policyFiles,
    ...feesFiles,
  ];

  function findDocuments(documentType) {
    return (
      task.documents?.filter(
        (doc) =>
          documentIds?.includes(doc.id) && doc.documentType === documentType,
      ) || []
    );
  }

  return {statementFiles, taxFiles, policyFiles, feesFiles, documents};
}
