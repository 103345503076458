import React, {useEffect} from 'react';

import PropTypes from 'prop-types';
import {Redirect, Route, useParams} from 'react-router-dom';
import {isNil} from 'ramda';

import {Text} from '@renofi/components-internal';
import {realEstateOwnedPropertyTypes} from '@renofi/utilities/src/enumTypes';

import PropertyAddressStep from '../PropertyAddressStep';
import SoldPropertyDetailsStep from '../SoldPropertyDetailsStep';
import PropertyCount from '../PropertyCount';
import {soldPropertyStep} from '../steps';

const SoldProperties = ({
  names,
  formData,
  onChange,
  removeStep,
  addStep,
  updateStep,
  status,
  borrowersInfo,
}) => {
  const {propertyId, type, step} = useParams();
  const {soldProperties = [], soldPropertyNumber} = formData;
  const index = propertyId - 1; // property #2 is a first item in soldProperties array
  const property = soldProperties?.length ? soldProperties[index] : {};
  const {propertyType} = property || {};

  useEffect(() => {
    updateWizardSteps();
  }, [propertyId, type, step, soldPropertyNumber]);

  useEffect(() => {
    if (status === 'updated') updateWizardSteps();
  }, [status]);

  function onChangePropertyNumber(newValue) {
    onChange('soldPropertyNumber', newValue);
  }

  function onChangeProperty(newValue) {
    const updated = updateProperties(newValue);
    onChange('soldProperties', updated.slice(0, soldPropertyNumber));
  }

  function updateProperties(newValue) {
    if (soldProperties[index] != null) {
      return soldProperties.map((value, i) =>
        index === i ? {...value, ...newValue} : value,
      );
    }
    return [...soldProperties, newValue];
  }

  function getTotalValue() {
    if (isNil(soldPropertyNumber)) return '';
    return String(soldPropertyNumber || 'No');
  }

  function updateWizardSteps() {
    for (let i = 1; i <= 20; i++) {
      if (i <= soldPropertyNumber) {
        const prevId = i === 1 ? 'sold' : `sold/${i}/root`;
        addStep(soldPropertyStep(i), prevId);

        if (isNil(soldProperties) || soldProperties?.length < 1) continue;

        const property = soldProperties[i - 1];
        const currentIndex = i;
        const stepLabels = [
          {id: `sold/${currentIndex}/root`, value: property?.streetAddressOne},
          {id: `sold/${currentIndex}/root`, value: property?.streetAddressOne},
          {
            id: `sold/${currentIndex}/address`,
            value: property?.streetAddressOne,
          },
          {
            id: `sold/${currentIndex}/details`,
            value: realEstateOwnedPropertyTypes[propertyType],
          },
        ];
        stepLabels.forEach(({id, value}) => {
          updateStep({id, key: 'info', value});
        });
      } else {
        removeStep(`sold/${i}/root`);
      }
    }

    updateStep({
      id: 'sold',
      key: 'info',
      value: getTotalValue(),
    });
  }

  return (
    <>
      <Route
        exact
        path="/:facet(borrower_information)/real-estate-wizard/sold"
        render={() => (
          <PropertyCount
            title={`Have ${names} sold any properties in the last 12 months?`}
            subtitle={`How many properties have ${names} sold in the last 12 months?`}
            onChange={onChangePropertyNumber}
            value={soldPropertyNumber}
          />
        )}
      />
      <Route path="/:facet(borrower_information)/real-estate-wizard/sold/:propertyId/root">
        <Redirect
          to={`/borrower_information/real-estate-wizard/sold/${propertyId}/address`}
        />
      </Route>
      <Route
        path="/:facet(borrower_information)/real-estate-wizard/sold/:propertyId/address"
        render={() => (
          <PropertyAddressStep
            onChange={onChangeProperty}
            property={property}
            title={
              <Text>
                Next, enter the <strong>address</strong> for the property sold.
              </Text>
            }
          />
        )}
      />
      <Route
        path="/:facet(borrower_information)/real-estate-wizard/sold/:propertyId/details"
        render={() => (
          <SoldPropertyDetailsStep
            onChange={onChangeProperty}
            property={property}
            borrowersInfo={borrowersInfo}
          />
        )}
      />
    </>
  );
};

SoldProperties.propTypes = {
  names: PropTypes.string,
  formData: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  removeStep: PropTypes.func.isRequired,
  addStep: PropTypes.func.isRequired,
  updateStep: PropTypes.func.isRequired,
  status: PropTypes.string,
  borrowersInfo: PropTypes.object,
};

export default SoldProperties;
