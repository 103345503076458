import React from 'react';

import {keys} from 'ramda';
import PropTypes from 'prop-types';

import states from '@renofi/utilities/src/states';
import SelectField from '@renofi/components-internal/src/SelectField';

const STATES_OPTIONS = (enableStateCodeLabels) => {
  return keys(states).map((key) => ({
    value: key,
    label: enableStateCodeLabels ? key : states[key],
  }));
};

function StatesField({
  disabled,
  label,
  onChange,
  placeholder,
  tabIndex,
  value,
  searchable,
  searchPlaceholder,
  enableStateCodeLabels = false,
  ...props
}) {
  return (
    <SelectField
      disabled={disabled}
      label={label}
      name="state"
      onChange={onChange}
      options={STATES_OPTIONS(enableStateCodeLabels)}
      placeholder={placeholder}
      tabIndex={tabIndex}
      value={value}
      searchable={searchable}
      searchPlaceholder={searchPlaceholder}
      {...props}
    />
  );
}

StatesField.propTypes = {
  disabled: PropTypes.bool,
  enableStateCodeLabels: PropTypes.bool,
  label: PropTypes.node,
  onChange: PropTypes.func,
  placeholder: PropTypes.node,
  tabIndex: PropTypes.number,
  value: PropTypes.string,
  searchable: PropTypes.bool,
  searchPlaceholder: PropTypes.string,
};

export default StatesField;
