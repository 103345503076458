/* eslint-disable max-lines */
import React, {useEffect, useMemo, useState, useRef} from 'react';

import PropTypes from 'prop-types';
import {pathOr} from 'ramda';
import {v4 as uuid} from 'uuid';

import {
  Text,
  TextField,
  Flex,
  Box,
  MoneyField,
  Checkbox,
  Button,
} from '@renofi/components-internal';
import Cross from '@renofi/icons/src/Cross';
import {useNotifications} from '@renofi/utilities/src/hooks';
import humanizeSnakeCase from '@renofi/utilities/src/humanizeSnakeCase';
import {isBusinessAsset} from '@renofi/modules-internal';
import {useDeleteAssetDetail, GET_PROJECT_TASKS} from '@renofi/graphql';
import {basic55} from '@renofi/theme/src/colors';

import ConfirmRemoveAsset from '../components/ConfirmRemoveAsset';
import {Content, Title} from '../styled';

import {AccountWrapper} from './styled';

const NEW_ACCOUNT = {
  financialInstitution: '',
  description: '',
  accountNumber: '',
  cashOrMarketValue: '',
  jointAccount: false,
  isNew: true,
  isDirty: true,
};

const Accounts = ({
  borrower,
  formData,
  assetType,
  borrowerRole,
  onChange,
  setFormData,
  hasCoBorrower,
  projectId,
}) => {
  const bottomRef = useRef(null);
  const {addNotification} = useNotifications();

  const [assetToRemove, setAssetToRemove] = useState(null);
  const {deleteAssetDetail, loading} = useDeleteAssetDetail({
    refetchQueries: [
      {
        query: GET_PROJECT_TASKS,
        variables: {projectId, facet: 'eligibility'},
      },
    ],
  });
  const [showAssetRemovalConfirmation, setShowAssetRemovalConfirmation] =
    useState(false);

  const accounts = useMemo(() => {
    return formData.filter((assetDetail) => {
      return (
        assetDetail.assetType === assetType &&
        assetDetail.borrowerRole === borrowerRole
      );
    });
  }, [formData, assetType, borrowerRole]);

  useEffect(() => {
    if (accounts.length === 0) {
      addAnotherAccount();
    }
  }, [accounts.length, assetType, borrowerRole]);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({behavior: 'smooth'});
  }, [accounts.length]);

  function getNewAccount() {
    return {
      ...NEW_ACCOUNT,
      ...(isBusinessAsset(assetType) ? {accountHolderName: ''} : {}),
      id: uuid(),
      assetType,
      borrowerRole,
    };
  }

  function addAnotherAccount() {
    const newAcc = getNewAccount();

    setFormData([...formData, newAcc]);
  }

  const onModalClose = () => {
    setShowAssetRemovalConfirmation(false);
    setAssetToRemove(null);
  };

  const onRemoveAsset = (assetDetail) => {
    setAssetToRemove(assetDetail);

    if (assetDetail.isNew) {
      removeAssetFromState(assetDetail.id);
      return;
    }

    setShowAssetRemovalConfirmation(true);
    setAssetToRemove(assetDetail);
  };

  const removeAssetFromState = (idToRemove) => {
    const newFormData = formData.filter(({id}) => id !== idToRemove);
    setFormData(newFormData);
  };

  const onConfirmRemoval = async () => {
    try {
      const response = await deleteAssetDetail({
        variables: {
          id: assetToRemove.id,
        },
      });

      const isSuccess = pathOr(
        null,
        ['data', 'deleteAssetDetail', 'success'],
        response,
      );

      if (!isSuccess) {
        throw new Error('Failed to delete asset');
      }

      removeAssetFromState(assetToRemove.id);
    } catch (error) {
      addNotification({
        variant: 'danger',
        content: 'Failed to delete asset',
        type: 'snackbar',
      });
    } finally {
      onModalClose();
    }
  };

  const firstName = borrower.firstName;
  const isBusiness = isBusinessAsset(assetType);

  return (
    <>
      <Content>
        <Title mb={3}>
          Next, enter the details for {firstName}’s{' '}
          <strong>{humanizeSnakeCase(assetType)}</strong> accounts
        </Title>

        <Box>
          {accounts.map((account, index) => {
            const {
              id,
              financialInstitution,
              accountHolderName,
              description,
              accountNumber,
              cashOrMarketValue,
              jointAccount,
            } = account;

            return (
              <AccountWrapper key={id}>
                <Flex justifyContent="space-between">
                  <Text color="#404040">
                    #{index + 1} {humanizeSnakeCase(assetType)} account
                  </Text>

                  {index > 0 ? (
                    <Button
                      css={{padding: 0}}
                      variant="link"
                      onClick={() => onRemoveAsset(account)}>
                      <Cross width={16} />
                    </Button>
                  ) : null}
                </Flex>

                <Box my="20px">
                  <TextField
                    label="Financial institution"
                    onChange={(newValue) =>
                      onChange(id, 'financialInstitution', newValue)
                    }
                    value={financialInstitution}
                  />
                  <Text mt="-14px" color={basic55} fontSize={12}>
                    Fidelity, Wells Fargo etc
                  </Text>
                </Box>

                {isBusiness ? (
                  <Box>
                    <TextField
                      label="Business Name"
                      onChange={(newValue) =>
                        onChange(id, 'accountHolderName', newValue)
                      }
                      value={accountHolderName}
                    />
                  </Box>
                ) : null}

                <Box mb="20px">
                  <TextField
                    label="Description"
                    onChange={(newValue) =>
                      onChange(id, 'description', newValue)
                    }
                    value={description}
                  />
                  <Text mt="-14px" color={basic55} fontSize={12}>
                    Include a short description that best identifies your asset
                    or where funds are being stored. Ex.{' '}
                    {isBusiness ? 'Business' : 'Joint'} checking account
                  </Text>
                </Box>

                <Flex flexDirection={['column', 'row']}>
                  <Box width={[1, 0.75]} mr={[0, 16]}>
                    <TextField
                      label="Account number"
                      onChange={(newValue) =>
                        onChange(id, 'accountNumber', newValue)
                      }
                      value={accountNumber}
                    />
                  </Box>
                  <Box width={[1, 'auto']}>
                    <MoneyField
                      label="Cash or market value"
                      onChange={(newValue) =>
                        onChange(id, 'cashOrMarketValue', newValue)
                      }
                      value={cashOrMarketValue}
                    />
                  </Box>
                </Flex>

                {hasCoBorrower ? (
                  <Box>
                    <Checkbox
                      label="This is a joint account shared with my co-borrower"
                      checked={jointAccount}
                      onChange={(newValue) =>
                        onChange(id, 'jointAccount', newValue)
                      }
                    />
                  </Box>
                ) : null}
              </AccountWrapper>
            );
          })}
        </Box>

        <div ref={bottomRef} />

        <Box mt="20px">
          <Button variant="link" onClick={addAnotherAccount}>
            + Add another {humanizeSnakeCase(assetType)} account
          </Button>
        </Box>
      </Content>
      {showAssetRemovalConfirmation ? (
        <ConfirmRemoveAsset
          assetType={assetType}
          onClose={() => setShowAssetRemovalConfirmation(false)}
          firstName={firstName}
          onAccept={onConfirmRemoval}
          loading={loading}
        />
      ) : null}
    </>
  );
};

Accounts.propTypes = {
  borrower: PropTypes.object,
  formData: PropTypes.array,
  onChange: PropTypes.func,
  assetType: PropTypes.string,
  borrowerRole: PropTypes.string,
  setFormData: PropTypes.func,
  hasCoBorrower: PropTypes.bool,
  projectId: PropTypes.string,
};

export default Accounts;
