/* eslint-disable max-lines */
import React from 'react';

import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import {useCurrentTask} from 'modules/project/hooks';

import {
  GET_PROJECT_TASKS,
  useMarkTaskDataAsReadyForProcessing,
  useMarkTaskFinishedByBorrower,
  useToggledBorrowerProject,
} from '@renofi/graphql';
import {Box, Flex, Modal, Text} from '@renofi/components-internal';
import {basic55} from '@renofi/theme';
import {validateUuidProp} from '@renofi/utilities/src/react';

import {Button} from './styled';
import THUMBS_UP from './thumbs-up.svg';
import useShowSavedModal from './useShowSavedModal';

const SaveFinishedModal = ({onYes, task}) => {
  const history = useHistory();
  const {show, setShowSavedModal} = useShowSavedModal();
  const {project} = useToggledBorrowerProject();
  const {sendEvent} = useCurrentTask({taskId: task?.id});
  const projectId = project?.id;
  const variables = {projectId, facet: 'contractor'};
  const refetchQueries = [
    {
      query: GET_PROJECT_TASKS,
      variables,
    },
  ];

  const {markTaskDataAsReadyForProcessing} =
    useMarkTaskDataAsReadyForProcessing();
  const {markTaskFinishedByBorrower} = useMarkTaskFinishedByBorrower({
    refetchQueries,
  });

  const onClickNo = async () => {
    setShowSavedModal(false);
    history.replace('/steps/1/contractor');
    const {id: taskId} = task || {};
    const vars = {taskId};
    await markTaskDataAsReadyForProcessing({variables: vars});
    await markTaskFinishedByBorrower({variables: vars});
    sendEvent('Secure/Task-Finished');
  };

  const onClickYes = () => {
    setShowSavedModal(false);
    history.replace('/steps/1/contractor/new');
    onYes();
  };

  return (
    <Modal show={show}>
      <Flex py={3} flexDirection="column" alignItems="center">
        <Box py={3}>
          <img src={THUMBS_UP} />
        </Box>
        <Text my={3} fontSize={24}>
          Contractor details saved!
        </Text>
        <Text my={3} color={basic55} fontSize={20}>
          Do you have more contractors to add?
        </Text>
        <Button block medium onClick={onClickYes}>
          Yes, I have more contractors to add
        </Button>
        <Box width={1} mt={3}>
          <Button block medium onClick={onClickNo} variant="secondary">
            I have no other contractors to add
          </Button>
        </Box>
      </Flex>
    </Modal>
  );
};

SaveFinishedModal.propTypes = {
  onYes: PropTypes.func.isRequired,
  task: PropTypes.shape({
    id: validateUuidProp,
    taskType: PropTypes.string,
  }),
};

export default SaveFinishedModal;
