import {pathOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';
import {GET_PROJECT_TASKS} from '@renofi/graphql/src/index';

import {SUBMIT_GOV_ID_DETAILS} from '../mutations';

import useMutation from './useMutation';

export default function useSubmitGovInformation({
  projectId,
  facet,
  onCompleted = noop,
  refetchQueriesFlag = true,
} = {}) {
  const [submitGovIdDetails, {data, loading, error}] = useMutation(
    SUBMIT_GOV_ID_DETAILS,
    {
      onCompleted,
      refetchQueries: refetchQueriesFlag
        ? [
            {
              query: GET_PROJECT_TASKS,
              variables: {
                facet,
                projectId,
              },
            },
          ]
        : [],
    },
  );

  const response = pathOr(null, ['submitGovIdDetails'], data);
  return {submitGovIdDetails, response, loading, error};
}
