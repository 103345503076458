import React, {useState, useMemo} from 'react';

import PropTypes from 'prop-types';
import {useHistory, Switch, Route} from 'react-router-dom';
import {isEmpty} from 'ramda';
import {TaskItem} from 'modules/layout';
import {TasksWizardContext} from 'modules/tasks-wizard';
import {useCurrentTask} from 'modules/project';
import {getTaskButtonLabels} from 'modules/project/utils';

import {Button, DocumentViewer} from '@renofi/components-internal';
import {
  AssetsDetails,
  ASSET_TYPE_DOCUMENT_MAPPER,
} from '@renofi/modules-internal';
import {isMobile} from '@renofi/theme';

import AssetsWizard from './AssetsWizard/AssetsWizard';

const AssetsTask = ({task, hasCoBorrower, project}) => {
  const history = useHistory();
  const [currentDocumentId, setCurrentDocumentId] = useState();

  const {status, documents: taskDocuments, assetDetails, taskType} = task;

  const {isProcessing, sendEvent} = useCurrentTask({
    taskId: task.id,
    facet: task.facet,
  });

  const buttonLabel = getTaskButtonLabels({
    status,
    isContinueTask: !isEmpty(assetDetails),
    taskType,
  });

  const documentTypes = Object.values(ASSET_TYPE_DOCUMENT_MAPPER);
  const documents = useMemo(() => {
    return taskDocuments.filter((doc) => {
      return documentTypes.includes(doc.documentType);
    });
  }, [taskDocuments, documentTypes]);

  function onOpenWizard() {
    sendEvent('Secure/Task-Started');

    history.push(`/borrower_information/assets-wizard`);
  }

  if (!project) {
    return null;
  }

  return (
    <>
      <TaskItem
        button={
          <Button
            block={isMobile()}
            medium={isMobile()}
            secondary={isProcessing}
            onClick={onOpenWizard}>
            {buttonLabel}
          </Button>
        }
        details="Include details of assets you want to be considered when determining your qualification for this loan. E.g. cash deposits, savings, business assets etc."
        task={task}>
        <AssetsDetails
          onClickDocument={setCurrentDocumentId}
          project={project}
          task={task}
          groupDocuments={false}
        />
        {currentDocumentId && (
          <DocumentViewer
            hasControls
            selectable
            itemId={currentDocumentId}
            visible={!!currentDocumentId}
            documents={documents}
            onClose={() => setCurrentDocumentId(null)}
            showCloseButton
            showBackButton={false}
          />
        )}
      </TaskItem>

      <Switch>
        <Route
          path={`/:facet(borrower_information)/assets-wizard`}
          render={() => (
            <TasksWizardContext.Consumer>
              {({dirty, setDirty}) => {
                const props = {
                  dirty,
                  onFinish: () => setDirty(false),
                  onChange: () => setDirty(true),
                };

                if (!task) {
                  return null;
                }

                return (
                  <AssetsWizard
                    hasCoBorrower={hasCoBorrower}
                    task={task}
                    project={project}
                    {...props}
                  />
                );
              }}
            </TasksWizardContext.Consumer>
          )}
        />
      </Switch>
    </>
  );
};

AssetsTask.propTypes = {
  task: PropTypes.object.isRequired,
  hasCoBorrower: PropTypes.bool,
  project: PropTypes.object,
};

export default AssetsTask;
