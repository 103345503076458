import React from 'react';

import PropTypes from 'prop-types';

import pluralize from '@renofi/utilities/src/pluralize';
import {basic55, renofiBlue} from '@renofi/theme/src/colors';
import {Box, Flex, Icon, Text} from '@renofi/components-internal';
import {isMobile} from '@renofi/theme';
import isFunction from '@renofi/utilities/src/isFunction';

import Button from '../Button';
import TaskStatus from '../TaskStatus';

import {ButtonWraper, StyledCard, Content, Break} from './styled';

const ANY_TYPE = PropTypes.oneOfType([
  PropTypes.element,
  PropTypes.node,
  PropTypes.string,
  PropTypes.func,
]);

const TaskCard = ({data, onClickView, statusCard}) => {
  const {badge, icon, tasksLength: totalTasks} = data;
  const {status, tasksCount} = badge || {};
  const title = isFunction(data.title) ? data.title() : data.title;

  const buttonSuffix = pluralize('task', totalTasks);
  const buttonVariant = status === 'completed' ? 'secondary' : 'primary';
  const statusText =
    status === 'outstanding'
      ? `${tasksCount} ${status} ${pluralize('task', tasksCount)}`
      : null;

  return (
    <StyledCard
      id={`card-${data.id}`}
      styledTitle={false}
      radius={16}
      isFullHeight
      statusCard={statusCard}
      completed={status === 'completed'}>
      <Content>
        <Flex flexDirection="column">
          <Box mb={2}>
            <TaskStatus status={status} text={statusText} />
          </Box>

          <Text color={renofiBlue} fontSize={20} fontWeight={700}>
            {title}
          </Text>
        </Flex>

        {Boolean(onClickView) && (
          <ButtonWraper>
            <Button
              css={{marginLeft: 'auto'}}
              block={isMobile()}
              medium={isMobile()}
              onClick={() => onClickView(data)}
              variant={buttonVariant}>
              View {buttonSuffix}
            </Button>
          </ButtonWraper>
        )}

        <Break />
        {icon && (
          <Box ml={2}>
            <Icon color={basic55} name={icon} />
          </Box>
        )}
        <Text mt={2} color={basic55} fontSize={16}>
          {data.content}
        </Text>
      </Content>
    </StyledCard>
  );
};

TaskCard.propTypes = {
  data: PropTypes.shape({
    badge: PropTypes.shape({
      status: PropTypes.string,
      tasksCount: PropTypes.number,
    }),
    content: ANY_TYPE,
    icon: PropTypes.string,
    id: PropTypes.string,
    tasksLength: PropTypes.number,
    title: ANY_TYPE,
  }),
  onClickView: PropTypes.func,
  statusCard: PropTypes.bool,
};
export default TaskCard;
