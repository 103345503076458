export default function useTogglePropertySteps() {
  return (toggleStep, route, property) => {
    const {
      existingMortgageLoan,
      taxAndInsuranceIncluded,
      homeownersAssociationFeesIncluded,
      homeownersAssociationFeesExist,
    } = property || {};

    toggleStep(
      `${route}/mortgage-statement`,
      existingMortgageLoan === undefined || existingMortgageLoan === true,
    );
    toggleStep(
      `${route}/insurance`,
      taxAndInsuranceIncluded === false || existingMortgageLoan === false,
    );
    toggleStep(
      `${route}/tax`,
      taxAndInsuranceIncluded === false || existingMortgageLoan === false,
    );
    toggleStep(
      `${route}/fees`,
      homeownersAssociationFeesExist === true &&
        homeownersAssociationFeesIncluded === false,
    );
  };
}
