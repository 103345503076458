import React, {Fragment, useCallback} from 'react';

import PropTypes from 'prop-types';
import {useReactiveVar} from '@apollo/client';
import {TASK_CATEGORIES} from 'modules/project/components/Project/constants';
import {useAddEditButtonVariant, useCurrentTask} from 'modules/project/hooks';
import {TASK_TYPES} from 'modules/project/components/Eligibility/constants';
import AddEditTaskButton from 'modules/project/components/AddEditTaskButton';

import {isMobile} from '@renofi/theme/src/breakpoints';
import {Button} from '@renofi/components-internal';

import UploadDocumentWizards from '../UploadDocumentWizards';
import CustomWizard from '../../../Task/components/CustomWizard';
import {customWizardIdVar} from '../utils';

const facet = TASK_CATEGORIES.ELIGIBILITY;

const CUSTOM_WIZARD_TASKS = [
  TASK_TYPES.GOVERNMENT_ID,
  TASK_TYPES.EMPLOYMENT_HISTORY,
  TASK_TYPES.INCOME,
  TASK_TYPES.ASSETS,
];

const EditTaskButton = ({taskId}) => {
  const {isCompleted, task} = useCurrentTask({taskId, facet});
  const [btnLabel, variant] = useAddEditButtonVariant({taskId, facet});
  // const [showCustomWizard, setShowCustomWizard] = useState(false);
  const customWizardId = useReactiveVar(customWizardIdVar);
  const showCustomWizard = customWizardId === taskId;

  const {canHaveDocument, taskType} = task || {};

  const isCustomWizardTask = CUSTOM_WIZARD_TASKS.includes(taskType);

  const setShowCustomWizardId = (value) => {
    customWizardIdVar(value);
  };

  const onClose = useCallback(() => {
    setShowCustomWizardId(false);
  }, []);

  if (isCompleted) {
    return null;
  }

  if (isCustomWizardTask) {
    return (
      <Fragment>
        <Button
          block={isMobile()}
          medium={isMobile()}
          onClick={() => setShowCustomWizardId(taskId)}
          variant={variant}>
          {btnLabel}
        </Button>

        {showCustomWizard && <CustomWizard onClose={onClose} task={task} />}
      </Fragment>
    );
  }
  if (canHaveDocument) {
    return (
      <AddEditTaskButton
        taskId={taskId}
        facet={facet}
        wizard={UploadDocumentWizards}
      />
    );
  }

  return null;
};

EditTaskButton.propTypes = {
  taskId: PropTypes.string.isRequired,
};

export default EditTaskButton;
