import React, {useState, useEffect, useRef} from 'react';

import {camelCase} from 'change-case';
import PropTypes from 'prop-types';

import {
  Menu,
  StyledBadge,
  MenuItem,
  StyledLabel,
  ItemIcon,
} from './Menu.styles';

const StyledMenu = ({data, onSelect, defaultOptionKey}) => {
  const isDefaultSet = useRef();
  const [selectedOption, setSelectedOption] = useState();

  const onOptionClick = (itemId) => {
    const selectedItem = data.find(({id}) => id === itemId);

    if (selectedItem.onClick) {
      selectedItem.onClick();
      return;
    }

    setSelectedOption(itemId);
    onSelect(selectedItem);
  };

  useEffect(() => {
    if (defaultOptionKey && !isDefaultSet.current) {
      setSelectedOption(defaultOptionKey);
      isDefaultSet.current = true;
    }
  }, [defaultOptionKey]);

  return (
    <Menu>
      {data.map((item, key) => (
        <MenuItem
          data-testid={`menu-item-${camelCase(item.title)}`}
          key={key}
          onClick={() => onOptionClick(item.id)}
          selectedOption={selectedOption === item.id}>
          <StyledLabel>
            <ItemIcon>{item.icon ? item.icon : null}</ItemIcon>
            <span>{item.title}</span>
          </StyledLabel>
          {item.badge && <StyledBadge>{item.badge}</StyledBadge>}
        </MenuItem>
      ))}
    </Menu>
  );
};

StyledMenu.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSelect: PropTypes.func,
  defaultOptionKey: PropTypes.number,
};

export default StyledMenu;
