import {useEffect} from 'react';

import {sendEvent} from '../analytics/utils';
import {addMonths} from '../dates';
import {Storage, STORAGE_PREFIX} from '../storage';

const useReturnsAfterOneMonthAnalytics = (user) => {
  useEffect(() => {
    const storage = new Storage({prefix: STORAGE_PREFIX});
    const afterOneMonth = storage.getItem('after:one:month');
    if (!afterOneMonth) {
      storage.setItem('after:one:month', addMonths(1));
      return;
    }
    const hasPastOneMonth = new Date() > new Date(afterOneMonth);
    if (hasPastOneMonth) {
      sendEvent('Secure/Returns-After-One-Month', {
        email: user?.email,
        id: user?.id,
      });
    }
  }, []);
};

export default useReturnsAfterOneMonthAnalytics;
