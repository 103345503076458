import React from 'react';

import PropTypes from 'prop-types';
import lazy from 'lib/asyncComponent';
import {TasksWizardContext} from 'modules/tasks-wizard';
import {TASK_CATEGORIES, useCurrentTask} from 'modules/project';

import {Loader} from '@renofi/components-internal';

const Wizard = lazy(
  () => import(/* webpackChunkName: "Wizard" */ './components/Wizard'),
);

const IncomeSourcesWizard = ({taskId, onClose}) => {
  const facet = TASK_CATEGORIES.ELIGIBILITY;
  const {loading, projectId, task} = useCurrentTask({
    taskId,
    facet: TASK_CATEGORIES.ELIGIBILITY,
  });

  if (loading) {
    return <Loader label={false} />;
  }

  return (
    <TasksWizardContext.Consumer>
      {({dirty, setDirty}) => {
        const props = {
          facet,
          task,
          taskId,
          projectId,
          onClose,
          dirty,
          onFinish: () => setDirty(false),
          onChange: () => setDirty(true),
        };

        return task && <Wizard {...props} />;
      }}
    </TasksWizardContext.Consumer>
  );
};

IncomeSourcesWizard.propTypes = {
  taskId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default IncomeSourcesWizard;
