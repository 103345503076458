import React, {useMemo} from 'react';

import PropTypes from 'prop-types';
import {isEmpty} from 'ramda';
import {TASK_CATEGORIES} from 'modules/project/components/Project/constants';
import {ManageDocuments} from 'modules/tasks-wizard';

import {useSubmitProjectDocument} from '@renofi/graphql';
import {Alert} from '@renofi/components-internal';
import {getDocumentTypeByAssetType} from '@renofi/modules-internal';

import {Content} from '../styled';
import StatementsTitle from '../components/StatementsTitle';

const CoBorrowerStatements = ({
  assetType,
  taskId,
  taskDocuments,
  borrowerRole,
  borrowerName,
  formData = [],
  onChange,
}) => {
  const {submitProjectDocument} = useSubmitProjectDocument();
  const documentType = getDocumentTypeByAssetType(assetType);

  const sourceDocs = useMemo(() => {
    return taskDocuments.filter(
      (doc) =>
        doc.borrowerRole === borrowerRole && doc.documentType === documentType,
    );
  }, [borrowerRole, documentType, taskDocuments?.length]);

  const jointAccount = useMemo(() => {
    return formData.find((detail) => {
      return (
        detail.assetType === assetType &&
        detail.borrowerRole === 'borrower' &&
        detail.jointAccount
      );
    });
  }, [formData, assetType]);

  const showAlert = useMemo(() => {
    if (!jointAccount) {
      return false;
    }

    const isBorrowerUploadedDocs = taskDocuments.some(
      (doc) =>
        doc.borrowerRole === 'borrower' && doc.documentType === documentType,
    );

    return isBorrowerUploadedDocs && isEmpty(sourceDocs);
  }, [jointAccount, taskDocuments, documentType, sourceDocs]);

  return (
    <Content mb={50}>
      <StatementsTitle assetType={assetType} borrowerName={borrowerName} />

      {showAlert ? (
        <Alert variant="info" mb={3}>
          The supporting documentation for {jointAccount.financialInstitution},
          marked as a joint account has already been uploaded.
        </Alert>
      ) : null}

      <ManageDocuments
        customMutation={submitProjectDocument}
        customResponsePath="submitProjectDocument"
        customMutationParams={{
          borrowerRole,
          documentType,
        }}
        customDocuments={sourceDocs}
        facet={TASK_CATEGORIES.ELIGIBILITY}
        onChange={onChange}
        taskId={taskId}
      />
    </Content>
  );
};

CoBorrowerStatements.propTypes = {
  taskId: PropTypes.string,
  taskDocuments: PropTypes.array,
  borrowerRole: PropTypes.string,
  borrowerName: PropTypes.string,
  assetType: PropTypes.string,
  formData: PropTypes.array,
  onChange: PropTypes.func,
};

export default CoBorrowerStatements;
