import React from 'react';

import PropTypes from 'prop-types';
import ManageDocuments from 'modules/tasks-wizard/components/ManageDocuments';

import {useSubmitRealEstateOwnedPropertyDocument} from '@renofi/graphql/src';

import {Content, Title} from '../styled';

const InsurancePolicyStep = ({
  task,
  address,
  realEstateOwnedPropertyId,
  documents,
  onUploadComplete,
}) => {
  const {submitRealEstateOwnedPropertyDocument} =
    useSubmitRealEstateOwnedPropertyDocument();
  const {id: taskId, facet} = task;

  return (
    <Content>
      <Title>
        Now, upload the <strong>homeowners insurance policy</strong> for{' '}
        {address}.
      </Title>

      <ManageDocuments
        forceRemoveButton
        customDocuments={documents}
        taskId={taskId}
        facet={facet}
        fileName="homeowners insurance policy"
        customMutation={submitRealEstateOwnedPropertyDocument}
        customResponsePath="submitRealEstateOwnedPropertyDocument"
        customMutationParams={{
          realEstateOwnedPropertyId,
          documentType: 'homowners_insurance_policy',
        }}
        onUploadComplete={onUploadComplete}
      />
    </Content>
  );
};

InsurancePolicyStep.propTypes = {
  task: PropTypes.object,
  address: PropTypes.string,
  realEstateOwnedPropertyId: PropTypes.string,
  documents: PropTypes.array,
  onUploadComplete: PropTypes.func,
};

export default InsurancePolicyStep;
