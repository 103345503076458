import React, {useState, useContext} from 'react';

import PropTypes from 'prop-types';
import {pick} from 'ramda';
import {context as ProjectTasksContext} from 'lib/projectTasks';

import {
  GET_BORROWER_PROJECTS,
  GET_PROJECT_TASKS,
  useBorrowersOnboardingFinishedByApplicant,
  useSubmitBorrowersAndPropertyInformation,
} from '@renofi/graphql';
import {sendEvent} from '@renofi/utilities/src/analytics';
import {propertyUseTypeKeys} from '@renofi/utilities/src/enumTypes';
import {useNotifications, useFeatureFlags} from '@renofi/utilities/src/hooks';

import NextButton from '../components/NextButton';
import {PROPS_TO_PICK, STEP_MAPPER} from '../constants';

const StepFooter = ({info, step, isFilled, projectId, setShowModal}) => {
  const {addNotification} = useNotifications();
  const [loading, setLoading] = useState(false);
  const {fetchAllProjectTasks} = useContext(ProjectTasksContext);

  const {flags} = useFeatureFlags(['enableReasonForRenovating']);

  const {id, propertyState, propertyUseType} = info || {};

  const {submitBorrowersAndPropertyInformation} =
    useSubmitBorrowersAndPropertyInformation({
      refetchQueries: [
        GET_BORROWER_PROJECTS,
        {
          query: GET_PROJECT_TASKS,
          variables: {projectId, facet: 'borrower_information'},
        },
        {
          query: GET_PROJECT_TASKS,
          variables: {projectId, facet: 'eligibility'},
        },
      ],
    });
  const {borrowersOnboardingFinishedByApplicant} =
    useBorrowersOnboardingFinishedByApplicant({});

  const onClickFinish = async () => {
    try {
      setLoading(true);

      await borrowersOnboardingFinishedByApplicant({
        variables: {
          borrowersAndPropertyInformationId: id,
        },
      });
      await submitBorrowersAndPropertyInformation({
        variables: {
          attributes: pick(PROPS_TO_PICK, info),
          id,
        },
      });
      await fetchAllProjectTasks({fetchPolicy: 'no-cache'});

      setLoading(false);
      setShowModal(false);
    } catch {
      addNotification({
        variant: 'danger',
        content: 'Failed to submit borrower and property information',
      });
    }
  };

  switch (step) {
    case STEP_MAPPER.Welcome:
      const nextStepIndex = isFilled
        ? STEP_MAPPER.FilledAddress
        : STEP_MAPPER.Address;

      return (
        <NextButton nextStepIndex={nextStepIndex}>{`Let's go`}</NextButton>
      );
    case STEP_MAPPER.FilledAddress:
      return null;
    case STEP_MAPPER.Address:
      return (
        <NextButton
          nextStepIndex={STEP_MAPPER.PropertyTrust}
          onClick={() =>
            sendEvent('Secure/Onboarding-Property-Input-Selected', {
              propertyState,
            })
          }>
          Next
        </NextButton>
      );

    case STEP_MAPPER.PropertyTrustWarning: // If property is in a trust.. skip property-title step
      return (
        <NextButton nextStepIndex={STEP_MAPPER.TypeOfHome}>Next</NextButton>
      );
    case STEP_MAPPER.TypeOfHome:
      return (
        <NextButton nextStepIndex={STEP_MAPPER.PropertyUsage}>Next</NextButton>
      );
    case STEP_MAPPER.PropertyUsage: {
      return (
        <NextButton nextStepIndex={STEP_MAPPER.TypeOfRenovation}>
          Next
        </NextButton>
      );
    }
    case STEP_MAPPER.TypeOfRenovation: {
      // if investment_property is selected - skip "Reason for Renovating" step
      const enableReasonForRenovating = Boolean(
        flags?.enableReasonForRenovating,
      );
      const isInvestmentPropertySelected =
        propertyUseType === propertyUseTypeKeys.investment_property;
      const isReasonForRenovatingNext =
        enableReasonForRenovating && !isInvestmentPropertySelected;

      const nextStepIndex = isReasonForRenovatingNext
        ? STEP_MAPPER.ReasonForRenovating
        : STEP_MAPPER.BorrowerOnLoan;

      return <NextButton nextStepIndex={nextStepIndex}>Next</NextButton>;
    }
    case STEP_MAPPER.ReasonForRenovating:
      return (
        <NextButton nextStepIndex={STEP_MAPPER.BorrowerOnLoan}>Next</NextButton>
      );
    case STEP_MAPPER.BorrowerInfo:
      return (
        <NextButton
          nextStepIndex={STEP_MAPPER.Finish}
          onClick={() =>
            sendEvent('Secure/Onboarding-Coborrower-Name-Selected')
          }>
          Next
        </NextButton>
      );
    case STEP_MAPPER.Finish:
      return (
        <NextButton loading={loading} onClick={onClickFinish}>
          View my tasks
        </NextButton>
      );
    default:
      return null;
  }
};

StepFooter.propTypes = {
  info: PropTypes.shape({
    id: PropTypes.string.isRequired,
    propertyState: PropTypes.string,
  }),
  projectId: PropTypes.string,
  step: PropTypes.number,
  isFilled: PropTypes.bool,
  place: PropTypes.object,
  setShowModal: PropTypes.func,
};

export default StepFooter;
