import {useMemo} from 'react';

import {assoc} from 'ramda';

import {getCookie} from './cookies';

export default function useCookies(keys = []) {
  const cookies = useMemo(
    () => keys.reduce((obj, key) => assoc(key, getCookie(key), obj), {}),
    [keys],
  );

  return {cookies};
}
