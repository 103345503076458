import {GOVERNMENT_ID, INCOME, ASSETS} from './constants';

export const getCanShowDocuments = (task) => {
  const {canHaveDocument, taskType} = task || {};

  switch (taskType) {
    case INCOME:
    case ASSETS:
    case GOVERNMENT_ID:
      return false;
    default:
      return canHaveDocument;
  }
};
