import React from 'react';

import PropTypes from 'prop-types';

import Assets from './Assets';
import Statements from './Statements';
import Accounts from './Accounts';

const BorrowerSteps = ({
  step,
  borrower,
  assets,
  formData,
  task,
  onAccountChange,
  borrowerRole,
  setFormData,
  hasCoBorrower,
  setAssets,
  setLoading,
  projectId,
  onChange,
  setCoBorrowerAssets,
}) => {
  const {id: stepId, assetType} = step;
  const {id: taskId, documents: taskDocuments = []} = task;

  if (!stepId.includes('primary-borrower')) {
    return null;
  }

  if (stepId.includes('assets')) {
    return (
      <Assets
        assets={assets}
        setAssets={setAssets}
        formData={formData}
        taskDocuments={taskDocuments}
        setFormData={setFormData}
        setLoading={setLoading}
        borrowerRole={borrowerRole}
        borrowerName={borrower.firstName}
        coborrowerName=""
        projectId={projectId}
        onChange={onChange}
        setCoBorrowerAssets={setCoBorrowerAssets}
      />
    );
  }

  if (stepId.includes('statements')) {
    return (
      <Statements
        taskId={taskId}
        taskDocuments={taskDocuments}
        borrowerRole={borrowerRole}
        assetType={assetType}
        stepId={stepId}
        borrowerName={borrower.firstName}
        onChange={onChange}
      />
    );
  }

  return (
    <Accounts
      assetType={assetType}
      borrower={borrower}
      borrowerRole={borrowerRole}
      formData={formData}
      onChange={onAccountChange}
      setFormData={setFormData}
      hasCoBorrower={hasCoBorrower}
      projectId={projectId}
    />
  );
};

BorrowerSteps.propTypes = {
  step: PropTypes.object,
  borrower: PropTypes.object,
  assets: PropTypes.object,
  formData: PropTypes.array,
  onAccountChange: PropTypes.func,
  project: PropTypes.object,
  task: PropTypes.object,
  borrowerRole: PropTypes.string,
  steps: PropTypes.array,
  setFormData: PropTypes.func,
  hasCoBorrower: PropTypes.bool,
  setAssets: PropTypes.func,
  setLoading: PropTypes.func,
  projectId: PropTypes.string,
  onChange: PropTypes.func,
  setCoBorrowerAssets: PropTypes.func,
};

export default BorrowerSteps;
