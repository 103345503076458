import React, {memo} from 'react';

import PropTypes from 'prop-types';
import {TASK_TYPES} from 'modules/project/components/Eligibility/constants';
import EmploymentHistoryWizard from 'modules/project/components/Eligibility/components/EmploymentHistoryWizard';
import GovIdWizard from 'modules/project/components/Eligibility/components/GovIdWizard';
import IncomeSourcesWizard from 'modules/project/components/Eligibility/components/IncomeSourcesWizard';

const CustomWizard = ({onClose, task}) => {
  const {id: taskId, taskType} = task;

  switch (taskType) {
    case TASK_TYPES.GOVERNMENT_ID:
      return <GovIdWizard onClose={onClose} taskId={taskId} {...task} />;
    case TASK_TYPES.EMPLOYMENT_HISTORY:
      return <EmploymentHistoryWizard onClose={onClose} taskId={taskId} />;
    case TASK_TYPES.INCOME:
      return <IncomeSourcesWizard onClose={onClose} taskId={taskId} />;
    default:
      return null;
  }
};

CustomWizard.propTypes = {
  onClose: PropTypes.func.isRequired,
  task: PropTypes.shape({
    id: PropTypes.string.isRequired,
    taskType: PropTypes.string.isRequired,
  }),
};

export default memo(CustomWizard);
