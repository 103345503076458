import React from 'react';

import * as appConfig from 'config';
import setupLocalization from 'lib/setupLocalization';
import Root from 'modules/root';

import {initializeApp} from '@renofi/modules-internal';
import polyfills from '@renofi/utilities/src/polyfills';

import './index.css';

import initAnalytics from './analytics';
import writeInitialData from './initialData';

const {firstRound, secondRound} = polyfills();

function init() {
  initializeApp({
    appConfig: {
      ...appConfig,
      mockQueries: [],
    },
    onInit: async ({config, client, storage}) => {
      setupLocalization();
      await initAnalytics({config, storage});
      await writeInitialData({client, storage});
    },
    rootComponent: <Root />,
  });
}

Promise.all(firstRound)
  .then(() => Promise.all(secondRound))
  .then(() => init())
  .catch((error) => {
    console.error('Failed fetching polyfills', error);
  });
