import React, {useMemo} from 'react';

import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import lazy from 'lib/asyncComponent';
import {TaskItem} from 'modules/layout';
import {TASK_STATUSES, useCurrentTask} from 'modules/project';

import {isMobile} from '@renofi/theme/src/breakpoints';
import {Button} from '@renofi/components-internal';

import {
  PRODUCT_CONFIRMATION_TASK_TYPES,
  PRODUCT_DETAILS_TASK_TYPE,
  AGREEMENT_TASK_TYPE,
  ADVISOR_CALL_TASK_TYPE,
} from '../constants';

const ProductDetailsTask = lazy(
  () =>
    import(
      /* webpackChunkName: "product-details-task" */ './ProductDetailsTask'
    ),
);

const AgreementTask = lazy(
  () => import(/* webpackChunkName: "agreement-task" */ './AgreementTask'),
);

const ScheduleCallTask = lazy(
  () =>
    import(/* webpackChunkName: "schedule-call-task" */ './ScheduleCallTask'),
);

const Task = (props) => {
  const {
    id,
    status,
    taskType,
    productDetailsConfirmation,
    certificateAcknowledgement,
    advisorFinalCallSchedule,
    completedAt,
    isProductDetailsCompleted,
    isCertificateAcknowledgementCompleted,
  } = props;
  const history = useHistory();
  const {sendEvent} = useCurrentTask({taskId: id});

  const completed = TASK_STATUSES.completed === status;
  const inProgress = TASK_STATUSES.outstanding === status;
  const isAgreementTask = taskType === AGREEMENT_TASK_TYPE;
  const isCallScheduleTask = taskType === ADVISOR_CALL_TASK_TYPE;
  const isProductDetailsTask = taskType === PRODUCT_DETAILS_TASK_TYPE;

  const label = PRODUCT_CONFIRMATION_TASK_TYPES[taskType];

  const showProductDetailsTask =
    completed && isProductDetailsTask && productDetailsConfirmation;
  const showAgreementTask =
    completed && isAgreementTask && certificateAcknowledgement;
  const showCallTask = completed && isCallScheduleTask;

  const isDisabled = useMemo(() => {
    const isCallScheduleBtnDisabled =
      isCallScheduleTask &&
      (!isProductDetailsCompleted || !isCertificateAcknowledgementCompleted);

    const isAgreementBtnDisabled =
      isAgreementTask && !isProductDetailsCompleted;

    return isCallScheduleBtnDisabled || isAgreementBtnDisabled;
  }, [
    isCallScheduleTask,
    isAgreementTask,
    isProductDetailsCompleted,
    isCertificateAcknowledgementCompleted,
  ]);

  const onLaunchWizard = () => {
    history.push(`/product_confirmation/${id}/step-1`);
    sendEvent('Secure/Task-Started');
  };

  return (
    <TaskItem
      button={
        <Button
          block={isMobile()}
          medium={isMobile()}
          onClick={onLaunchWizard}
          disabled={isDisabled}
          variant={inProgress ? 'primary' : 'secondary'}>
          {label}
        </Button>
      }
      keepTaskOpenOnStatusChange={isDisabled}
      task={props}>
      {showProductDetailsTask && (
        <ProductDetailsTask taskId={id} details={productDetailsConfirmation} />
      )}
      {showAgreementTask && (
        <AgreementTask
          completedAt={completedAt}
          title={certificateAcknowledgement?.title}
        />
      )}
      {showCallTask && <ScheduleCallTask details={advisorFinalCallSchedule} />}
    </TaskItem>
  );
};

Task.propTypes = {
  id: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  taskType: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  productDetailsConfirmation: PropTypes.shape({
    address: PropTypes.string,
    applicants: PropTypes.array,
    email: PropTypes.string,
    phone: PropTypes.string,
    name: PropTypes.string,
    dtiRatio: PropTypes.number,
    creditScore: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    titleHolders: PropTypes.string,
    appraisedValue: PropTypes.number,
    loanType: PropTypes.string,
    lenderId: PropTypes.string,
    lienPosition: PropTypes.number,
    interestRate: PropTypes.number,
    estimatedMonthlyPayment: PropTypes.array,
    renovationAmount: PropTypes.number,
    loanAmount: PropTypes.number,
    declineCommentsPersonalDetails: PropTypes.string,
    declineCommentsPropertyDetails: PropTypes.string,
    declineCommentsLoanDetails: PropTypes.string,
    applicantSubmittedAt: PropTypes.string,
  }),
  certificateAcknowledgement: PropTypes.shape({
    applicantName: PropTypes.string,
    coapplicantName: PropTypes.string,
    content: PropTypes.string,
    title: PropTypes.string,
    createdAt: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    applicantSsnSuffix: PropTypes.string,
    coapplicantSsnSuffix: PropTypes.string,
    taskId: PropTypes.string.isRequired,
    updatedAt: PropTypes.string.isRequired,
  }),
  advisorFinalCallSchedule: PropTypes.shape({
    id: PropTypes.string.isRequired,
    callEventId: PropTypes.string,
    callEndsAt: PropTypes.string,
    callStartsAt: PropTypes.string,
  }),
  completedAt: PropTypes.string,
  isProductDetailsCompleted: PropTypes.bool,
  isCertificateAcknowledgementCompleted: PropTypes.bool,
};

export default Task;
