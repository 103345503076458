import {useEffect, useState} from 'react';

import {
  filter,
  find,
  flatten,
  pathOr,
  pipe,
  propEq,
  propOr,
  values,
} from 'ramda';
import {camelCase} from 'change-case';
import {useApolloClient} from '@apollo/client';

import {GET_PROJECT_TASKS} from '@renofi/graphql/src';
import {useToggledBorrowerProject} from '@renofi/graphql/src/hooks/renovation/queries';
import {
  useDocumentAccepted,
  useDocumentRejected,
  useTaskStatusUpdated,
} from '@renofi/utilities/src/pusher';

import {TASK_CATEGORIES} from '../../modules/project';

import {filterOutstanding, rejectEmptySoftPull} from './utils';

const FACET_VALUES = values(TASK_CATEGORIES);

export default function useAllProjectTasks() {
  const [allTasks, setAllTasks] = useState([]);
  const [loading, setLoading] = useState(false);

  const client = useApolloClient();
  const {project} = useToggledBorrowerProject();
  const projectId = project?.id;

  const onEventUpdate = async ({data} = {}) => {
    const newTasks = propOr([], 'projectTasks', data);
    const tasks = await fetchAllProjectTasks();
    setAllTasks(
      tasks.map((t) => {
        const updated = find(propEq('id', t.id), newTasks);
        return Boolean(updated) ? updated : t;
      }),
    );
  };

  useDocumentAccepted(projectId);
  useDocumentRejected(projectId);
  useTaskStatusUpdated(projectId, onEventUpdate);

  useEffect(() => {
    (async () => {
      if (!projectId) {
        return;
      }

      await fetchAllProjectTasks();
    })();
  }, [projectId]);

  const groupTasks = () => {
    const groupedTasks = FACET_VALUES.reduce((obj, facet) => {
      return {
        ...obj,
        [camelCase(facet)]: pipe(
          filter(propEq('facet', facet)),
          rejectEmptySoftPull,
        )(allTasks),
      };
    }, {});
    return [groupedTasks, filterOutstanding(allTasks).length];
  };

  const fetchAllProjectTasks = async ({fetchPolicy = 'cache-first'} = {}) => {
    setLoading(true);
    const rsp = await Promise.all(
      FACET_VALUES.map(async (facet) => {
        const response = await client.query({
          errorPolicy: 'all',
          fetchPolicy,
          query: GET_PROJECT_TASKS,
          variables: {projectId, facet},
        });

        return pathOr([], ['data', 'projectTasks'], response);
      }),
    );
    const projectTasks = flatten(rsp);

    setLoading(false);
    setAllTasks(projectTasks);

    return projectTasks;
  };

  const refetchProjectTasks = async ({taskId}) => {
    const task = find(propEq('id', taskId), allTasks);
    const facet = task?.facet;
    if (!facet) {
      return;
    }

    const response = await client.query({
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
      query: GET_PROJECT_TASKS,
      variables: {projectId, facet},
    });
    const newTasks = pathOr([], ['data', 'projectTasks'], response);
    setAllTasks((state) =>
      state.map((t) => {
        const updated = find(propEq('id', t.id), newTasks);
        return Boolean(updated) ? updated : t;
      }),
    );
  };

  const [tasks, outstanding] = groupTasks();

  return {
    ...tasks,
    allTasks,
    loading,
    outstanding,
    fetchAllProjectTasks,
    refetchProjectTasks,
  };
}
