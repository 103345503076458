import {last, pathOr} from 'ramda';

import {
  useAppraisalOrders,
  useProjectTasks,
  useToggledBorrowerProject,
} from '@renofi/graphql';

import {TASK_CATEGORIES} from '../../modules/project/components/Project/constants';

export default () => {
  const {project} = useToggledBorrowerProject();
  const {id: projectId} = project || {};
  const appraisalReportReviewFinishedAt = pathOr(
    null,
    ['timeline', 'appraisalReportReviewFinishedAt'],
    project,
  );

  const {appraisalOrders} = useAppraisalOrders({
    variables: {projectId},
  });
  const appraisalOrder = last(appraisalOrders);

  const {tasks: appraisalTasks} = useProjectTasks({
    variables: {projectId, facet: TASK_CATEGORIES.APPRAISAL},
  });
  const {tasks: productConfirmationTasks} = useProjectTasks({
    variables: {projectId, facet: TASK_CATEGORIES.PRODUCT},
  });
  const hasProductConfirmation = Boolean(productConfirmationTasks.length);

  return {
    appraisalOrders,
    appraisalOrder,
    hasProductConfirmation,
    tasks: appraisalTasks,
    appraisalReportReviewFinishedAt,
  };
};
