import React from 'react';

import PropTypes from 'prop-types';
import lazy from 'lib/asyncComponent';
import {TasksWizardContext} from 'modules/tasks-wizard';
import {TASK_CATEGORIES} from 'modules/project';

import {useProjectTasks, useToggledBorrowerProject} from '@renofi/graphql';
import {Loader} from '@renofi/components-internal';

const Wizard = lazy(
  () => import(/* webpackChunkName: "Wizard" */ './components/Wizard'),
);

const EmploymentHistoryWizard = ({taskId, onClose}) => {
  const facet = TASK_CATEGORIES.ELIGIBILITY;
  const {project} = useToggledBorrowerProject();
  const projectId = project?.id;

  const {tasks, loading} = useProjectTasks({
    variables: {projectId, facet: TASK_CATEGORIES.ELIGIBILITY},
  });
  const task = tasks.find((task) => task.id === taskId);

  if (loading) {
    return <Loader label={false} />;
  }

  return (
    <TasksWizardContext.Consumer>
      {({dirty, setDirty}) => {
        const props = {
          facet,
          task,
          taskId,
          projectId,
          onClose,
          dirty,
          onChange: () => setDirty(true),
        };

        return task && <Wizard {...props} />;
      }}
    </TasksWizardContext.Consumer>
  );
};

EmploymentHistoryWizard.propTypes = {
  taskId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EmploymentHistoryWizard;
