export const getHistory =
  (coborrower = false) =>
  (history) =>
    Boolean(history.coborrowerEmploymentHistory) === coborrower;

export const sumAllIncomes = (employmentHistory = {}) => {
  const {
    grossMonthlyIncomeBase = 0,
    grossMonthlyIncomeBonus = 0,
    grossMonthlyIncomeCommission = 0,
    grossMonthlyIncomeMilitaryEntitlements = 0,
    grossMonthlyIncomeOther = 0,
    grossMonthlyIncomeOvertime = 0,
  } = employmentHistory;

  return [
    grossMonthlyIncomeBase,
    grossMonthlyIncomeBonus,
    grossMonthlyIncomeCommission,
    grossMonthlyIncomeMilitaryEntitlements,
    grossMonthlyIncomeOther,
    grossMonthlyIncomeOvertime,
  ].reduce((t, v) => t + v, 0);
};
