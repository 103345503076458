import {projectStatus} from '@renofi/utilities/src/enums';

import {step1, step2, step3, step4} from '../../../routes';

export const getRedirectPathByStatus = (status) => {
  const {
    closed,
    renovating,
    preparing,
    inProgress,
    withLender,
    closedWithLender,
  } = projectStatus;

  switch (status) {
    case preparing:
    case inProgress:
      return step1();
    case withLender:
      return step2();
    case closedWithLender:
    case renovating:
      return step3();
    case closed:
      return step4();
    default:
      return step1();
  }
};
