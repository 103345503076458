import React, {useState} from 'react';

import PropTypes from 'prop-types';

import ArrowIcon from '@renofi/icons/src/Arrow';
import HomeIcon from '@renofi/icons/src/HomeIcon';
import formatMoney from '@renofi/utilities/src/formatMoney';
import {dangerRegular} from '@renofi/theme';

import Box from '../Box';

import {
  StyledMenu,
  StyledContent,
  StyledBackdrop,
  LabelSection,
  ContentItem,
} from './CollapsedMenu.styles';

const getMoney = (v) => formatMoney(v, {fractional: false});

const CollapsedMenu = ({current, data, onSelectItem}) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const hasMultipleProjects = data?.length > 1;

  const onClickLabel = () => {
    if (!hasMultipleProjects) {
      return;
    }
    setIsCollapsed(!isCollapsed);
  };

  const onItemClick = (key) => {
    onSelectItem(data[key]);
    setIsCollapsed(!isCollapsed);
  };

  return (
    <StyledMenu>
      <LabelSection multiple={hasMultipleProjects} onClick={onClickLabel}>
        <Box fontSize={[20, 'inherit']}>
          <strong>{current?.streetAddressOne}</strong>
          <div>{current?.loanType}</div>
          <div>{getMoney(current?.loanValue)}</div>
        </Box>

        {hasMultipleProjects ? (
          <ArrowIcon
            color={dangerRegular}
            direction={isCollapsed ? 'down' : 'up'}
          />
        ) : null}
      </LabelSection>
      <StyledContent collapsed={isCollapsed}>
        {data?.map((project, key) => (
          <ContentItem
            key={key}
            onClick={() => onItemClick(key)}
            selected={current?.id === project.id}>
            <HomeIcon style={{marginRight: 22, marginTop: 4}} />

            <Box fontSize={[20, 'inherit']}>
              <strong>{project?.streetAddressOne}</strong>
              <div>{project?.loanType || 'RenoFi personal loan'}</div>
              <div>{getMoney(project?.loanValue)}</div>
            </Box>
          </ContentItem>
        ))}
      </StyledContent>
      <StyledBackdrop
        collapsed={isCollapsed}
        onClick={() => setIsCollapsed(!isCollapsed)}
      />
    </StyledMenu>
  );
};

CollapsedMenu.propTypes = {
  current: PropTypes.shape({
    id: PropTypes.string.isRequired,
    streetAddressOne: PropTypes.string,
    loanType: PropTypes.string,
    loanValue: PropTypes.number,
  }),
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSelectItem: PropTypes.func.isRequired,
  selectedIndex: PropTypes.number,
};

export default CollapsedMenu;
