import React, {useState} from 'react';

import PropTypes from 'prop-types';
import {isNil} from 'ramda';
import ChangePhone from 'modules/phone';

import {analyticsIdentify, sendEvent} from '@renofi/utilities/src/analytics';
import {useNotifications} from '@renofi/utilities/src/hooks';
import {
  useGetUser,
  useToggledBorrowerProject,
  useSubmitSmsConsentRenovationProgress,
  useMarkTaskDataAsReadyForProcessing,
} from '@renofi/graphql/src/hooks';
import {
  Alert,
  Box,
  ButtonGroup,
  Flex,
  Link,
  Text,
} from '@renofi/components-internal';
import {basic55} from '@renofi/theme/src/colors';
import Phone from '@renofi/icons/src/Phone';
import {isMobile} from '@renofi/theme/src/breakpoints';

const CONSENT_OPTIONS = {
  yes: 'I agree to receive SMS from RenoFi',
  no: 'I disagree - not recommended',
};

const CONSENT_MESSAGE = `
It's required to keep the Lender posted about the
renovation progress on the Renovate! step. To make this process easier we'll periodically
send you SMS to which you can quickly reply with an update
(we require a response within 48 hours) - view full Terms & Services.`;

const updateRegalVoice = (id, phone, subscribed, text) => {
  // Segment.identify is used to update the user's consent in RegalVoice
  analyticsIdentify(id, {
    phone,
    optIn: [
      {
        channel: 'sms',
        subscribed,
        timestamp: new Date(),
        source: 'secure',
        text,
      },
    ],
  });
};

const Consent = ({accepted, phoneNumber: smsNumber, taskId}) => {
  const {user} = useGetUser();
  const {project} = useToggledBorrowerProject();
  const {submitSmsConsent, error: consentError} =
    useSubmitSmsConsentRenovationProgress();
  const {markTaskDataAsReadyForProcessing, error: taskCompletionError} =
    useMarkTaskDataAsReadyForProcessing();
  const {addNotification} = useNotifications();
  const [active, setActive] = useState(false);
  const [consent, setConsent] = useState(accepted);
  const [phoneNumberChanged, setPhoneNumberChanged] = useState(false);

  const phoneNumber = smsNumber || user?.phoneNumber;
  const value = consent ? 'yes' : 'no';

  const handleUpdateConsent = async (value) => {
    const accepted = value === 'yes';

    sendEvent('Secure/Change-SMS-Consent', {accepted});
    setConsent(accepted);

    await submitSmsConsent({
      variables: {
        taskId,
        smsConsentRenovationProgress: {
          phoneNumber,
          accepted,
        },
      },
    });

    await markTaskDataAsReadyForProcessing({variables: {taskId}});

    if (consentError || taskCompletionError) {
      addNotification({
        variant: 'danger',
        content: `Failed to ${
          accepted ? 'accept' : 'reject'
        } the consent for SMS reminders`,
        type: 'snackbar',
      });

      return;
    }

    if (phoneNumberChanged) {
      // Unsubscribing the initial phone number from RegalVoice
      updateRegalVoice(project.leadScenarioId, user.phoneNumber, false, '');

      updateRegalVoice(
        project.leadScenarioId,
        phoneNumber,
        accepted,
        CONSENT_MESSAGE,
      );
    } else if (!accepted) {
      updateRegalVoice(project.leadScenarioId, phoneNumber, false, '');
    }
  };

  return (
    <>
      <Box mt={16}>
        <Flex alignItems="center">
          <Text
            fontSize={14}
            color={basic55}
            lineHeight="24px"
            css={{display: 'flex', alignItems: 'center'}}>
            Your number: <Phone height={20} width={20} /> {phoneNumber}
            <Link
              to=""
              css={{marginLeft: 4}}
              onClick={(event) => {
                event.preventDefault();
                setActive(true);
              }}>
              Change
            </Link>
          </Text>
        </Flex>
        <Box width={isMobile() ? 1 : 575} mt={16}>
          <ButtonGroup
            value={isNil(consent) ? null : value}
            options={CONSENT_OPTIONS}
            onChange={handleUpdateConsent}
          />
        </Box>
        {consent === false && (
          <Alert variant="info">
            <Text fontSize={16} lineHeight="24px">
              We strongly recommend agreeing to receive SMS asking about updates
              periodically - it’s easier for you and us. Alternatively, you’re
              required to keep the Lender posted on renovation progress by
              proactively adding updates on the Renovate step.
            </Text>
          </Alert>
        )}
      </Box>
      {active && (
        <ChangePhone
          phoneNumber={phoneNumber}
          onClose={() => setActive(false)}
          setPhoneNumberChanged={setPhoneNumberChanged}
        />
      )}
    </>
  );
};

Consent.propTypes = {
  taskId: PropTypes.string.isRequired,
  accepted: PropTypes.bool,
  phoneNumber: PropTypes.string,
};

export default Consent;
