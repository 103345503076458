const MORTGAGE_STATEMENT = 'mortgage_statement';
export const GOVERNMENT_ID = 'government_issued_id';
export const EMPLOYMENT_HISTORY = 'employment_history';
export const PERSONAL_INFO_AND_RESIDENCY = 'personal_info_and_residency';
const ADDITIONAL_ELIGIBILITY_EVIDENCE = 'additional_eligibility_evidence';
const TAX_RETURNS_DOCS = 'tax_returns_docs';
const ACK_DELTA = 'ack_delta';
const REAL_ESTATE_OWNED = 'real_estate_owned';
export const INCOME = 'income';
export const ASSETS = 'assets';
export const IDENTITY_VERIFICATION = 'identity_verification';

export const TASK_TYPES = {
  MORTGAGE_STATEMENT: MORTGAGE_STATEMENT,
  GOVERNMENT_ID: GOVERNMENT_ID,
  EMPLOYMENT_HISTORY: EMPLOYMENT_HISTORY,
  PERSONAL_INFO_AND_RESIDENCY: PERSONAL_INFO_AND_RESIDENCY,
  ADDITIONAL_ELIGIBILITY_EVIDENCE: ADDITIONAL_ELIGIBILITY_EVIDENCE,
  TAX_RETURNS_DOCS: TAX_RETURNS_DOCS,
  ACK_DELTA: ACK_DELTA,
  REAL_ESTATE_OWNED: REAL_ESTATE_OWNED,
  INCOME: INCOME,
  ASSETS: ASSETS,
  IDENTITY_VERIFICATION: IDENTITY_VERIFICATION,
};

export const SELF_EMPLOYMENT_INCOME = [
  {
    label: 'Sole Proprietor',
    value: 'sole_proprietor',
  },
  {
    label: 'S-Corp',
    value: 's_corp',
  },
  {
    label: 'C-Corp',
    value: 'c_corp',
  },
  {
    label: 'LLC',
    value: 'llc',
  },
  {
    label: 'Partnership',
    value: 'partnership',
  },
  {
    label: 'Other',
    value: 'other',
  },
  {
    label: 'Bonus or Variable income',
    value: 'bonus_or_variable_income',
  },
];

export const SELF_EMPLOYMENT_ENTITIES = {
  sole_proprietor: ['the past 2 years of 1040 forms'],
  s_corp: ['1040', '1120-S', 'K1', 'W2'],
  c_corp: ['1040', '1120'],
  llc: ['P&L statements', 'Business taxes forms 1120 or 1065', 'K1 forms'],
  other: ['P&L statements', 'Business taxes forms 1120 or 1065', 'K1 forms'],
  partnership: ['1040', '1060', 'K1 forms'],
  bonus_or_variable_income: ['End of year pay stub'],
};
