import React, {useMemo} from 'react';

import PropTypes from 'prop-types';
import {TASK_CATEGORIES} from 'modules/project/components/Project/constants';
import {ManageDocuments} from 'modules/tasks-wizard';

import {useSubmitProjectDocument} from '@renofi/graphql';
import {getDocumentTypeByAssetType} from '@renofi/modules-internal';

import StatementsTitle from '../components/StatementsTitle';
import {Content} from '../styled';

const Statements = ({
  assetType,
  taskId,
  taskDocuments,
  borrowerRole,
  borrowerName,
  onChange,
}) => {
  const {submitProjectDocument} = useSubmitProjectDocument();
  const documentType = getDocumentTypeByAssetType(assetType);

  const sourceDocs = useMemo(() => {
    return taskDocuments.filter(
      (doc) =>
        doc.borrowerRole === borrowerRole && doc.documentType === documentType,
    );
  }, [borrowerRole, documentType, taskDocuments?.length]);

  return (
    <Content mb={40}>
      <StatementsTitle assetType={assetType} borrowerName={borrowerName} />

      <ManageDocuments
        customMutation={submitProjectDocument}
        customResponsePath="submitProjectDocument"
        customMutationParams={{
          borrowerRole,
          documentType,
        }}
        customDocuments={sourceDocs}
        facet={TASK_CATEGORIES.ELIGIBILITY}
        onChange={onChange}
        taskId={taskId}
      />
    </Content>
  );
};

Statements.propTypes = {
  taskId: PropTypes.string,
  taskDocuments: PropTypes.array,
  borrowerRole: PropTypes.string,
  borrowerName: PropTypes.string,
  assetType: PropTypes.string,
  onChange: PropTypes.func,
};

export default Statements;
