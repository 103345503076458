import React, {memo, useEffect, useMemo} from 'react';

import PropTypes from 'prop-types';
import {isEmpty, propOr, propEq, pathOr} from 'ramda';
import lazy from 'lib/asyncComponent';
import {FacetIntro} from 'modules/layout';

import Card from '@renofi/components-internal/src/next/Card';
import {Alert, Loader} from '@renofi/components-internal';
import {useProjectTasks, useToggledBorrowerProject} from '@renofi/graphql';

import useApplicationState from '../../hooks/useApplicationState';
import useTaskHandlers from '../../hooks/useTaskHandlers';
import useDocumentHandlers from '../../hooks/useDocumentHandlers';
import {TASK_CATEGORIES} from '../Project/constants';
import Modals from '../Modals';
import BorrowerInformation from '../Borrowers';
import {RealEstateTask} from '../Borrowers/components/RealEstate';
import {WizardUploadTask} from '../Task';
import AssetsTask from '../Borrowers/components/AssetsTask/AssetsTask';

import EmploymentHistoryWizard from './components/EmploymentHistoryWizard';
import GovIdWizard from './components/GovIdWizard';
import UploadDocumentWizards from './components/UploadDocumentWizards';
import EditTaskButton from './components/EditTaskButton';
import ExtraDetails from './components/ExtraDetails';
import {EMPLOYMENT_HISTORY, GOVERNMENT_ID, TASK_TYPES} from './constants';
import {getCanShowDocuments} from './utils';

const Progress = lazy(
  () => import(/* webpackChunkName: "project-progress" */ '../Progress'),
);

const getWizardByType = ({canHaveDocument, taskType}) => {
  switch (true) {
    case taskType === EMPLOYMENT_HISTORY:
      return EmploymentHistoryWizard;
    case taskType === GOVERNMENT_ID:
      return GovIdWizard;
    case canHaveDocument:
      return UploadDocumentWizards;
    default:
      return null;
  }
};

const SEPARATED_TASKS = [
  TASK_TYPES.GOVERNMENT_ID,
  TASK_TYPES.PERSONAL_INFO_AND_RESIDENCY,
  TASK_TYPES.REAL_ESTATE_OWNED,
  TASK_TYPES.ASSETS,
];

const Eligibility = ({onTaskComplete}) => {
  const facet = TASK_CATEGORIES.ELIGIBILITY;
  const {project} = useToggledBorrowerProject();
  const projectId = propOr(null, 'id', project);
  const {
    tasks,
    fetch: fetchTasks,
    loading: loadingTasks,
  } = useProjectTasks({lazy: true});

  const realEstateTask =
    tasks.find(propEq('taskType', TASK_TYPES.REAL_ESTATE_OWNED)) || {};
  const assetsTask = tasks.find(propEq('taskType', TASK_TYPES.ASSETS)) || {};

  const filteredTasks = useMemo(
    () => tasks.filter(({taskType}) => !SEPARATED_TASKS.includes(taskType)),
    [tasks],
  );

  const {
    onConfirmUpload,
    onShowPrompt,
    onRemoveDocument,
    loading,
    removedDocument,
    uploadedDocument,
  } = useTaskHandlers({tasks, projectId, facet});

  const {onAcceptFiles} = useDocumentHandlers({
    tasks,
    projectId,
    facet,
  });

  const {
    applicationState,
    onCloseRejection,
    onCloseAdditionalRequest,
    onShowRejection,
  } = useApplicationState();

  useEffect(() => {
    if (projectId) {
      fetchTasks({
        variables: {
          facet,
          projectId,
        },
      });
    }
  }, [projectId]);

  const hasCoBorrower = pathOr(
    false,
    ['borrowersAndPropertyInformation', 'coborrowerIsPresent'],
    project,
  );

  if (loadingTasks) {
    return <Loader label={false} />;
  }

  return (
    <>
      <Modals
        tasks={tasks}
        loading={loading}
        rejection={applicationState?.rejection}
        removeIds={removedDocument}
        addFilesData={uploadedDocument}
        additionalRequest={applicationState?.additionalRequest}
        onAcceptFiles={onAcceptFiles}
        onConfirmUpload={onConfirmUpload}
        onShowPrompt={onShowPrompt}
        onRemoveDocument={onRemoveDocument}
        onCloseRejection={onCloseRejection}
        onCloseAdditionalRequest={onCloseAdditionalRequest}>
        <BorrowerInformation
          onTaskComplete={onTaskComplete}
          showCoBorrowerIcon={hasCoBorrower}
        />
        {!isEmpty(filteredTasks) ||
        !isEmpty(realEstateTask) ||
        !isEmpty(assetsTask) ? (
          <Card p={0}>
            <FacetIntro title="Income and assets">
              <Alert>
                Please provide documents about your income, assets, debt, and
                other information that will allow us to determine your
                eligibility for a new RenoFi loan.
              </Alert>
            </FacetIntro>
            {filteredTasks.map((task) => {
              return (
                <WizardUploadTask
                  customAddEditButton={EditTaskButton}
                  extra={ExtraDetails}
                  onRemoveFile={(value) =>
                    onShowPrompt({documentId: value, taskId: task.id})
                  }
                  onShowRejection={(documentId) =>
                    onShowRejection({documentId, taskId: task.id})
                  }
                  onTaskComplete={onTaskComplete}
                  key={task.id}
                  showCoBorrowerIcon={hasCoBorrower}
                  showTaskDocuments={getCanShowDocuments(task)}
                  showDetails
                  task={task}
                  wizard={getWizardByType(task)}
                />
              );
            })}
            {/* TODO: move realEstateTask and assetsTask under WizardUploadTask */}
            {!isEmpty(realEstateTask) && (
              <RealEstateTask task={realEstateTask} />
            )}
            {!isEmpty(assetsTask) ? (
              <AssetsTask
                task={assetsTask}
                hasCoBorrower={hasCoBorrower}
                project={project}
              />
            ) : null}
          </Card>
        ) : null}
        <Progress />
      </Modals>
    </>
  );
};

Eligibility.propTypes = {
  onTaskComplete: PropTypes.func.isRequired,
};

export default memo(Eligibility);
