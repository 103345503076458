import {pathOr} from 'ramda';

import {useRemoveDocument} from '@renofi/graphql/src/hooks';
import {GET_PROJECT_TASKS} from '@renofi/graphql/src';

const useRemoveBorrowerDocument = ({projectId, facet}) => {
  const variables = {projectId, facet};

  const {removeDocument} = useRemoveDocument({
    refetchQueries: [
      {
        query: GET_PROJECT_TASKS,
        variables,
      },
    ],
  });

  const removeDoc = async (documentId) => {
    const response = await removeDocument({
      variables: {id: documentId},
    });

    const isSucccess = pathOr(
      false,
      ['data', 'removeDocument', 'success'],
      response,
    );

    return isSucccess;
  };

  return {
    removeDocument: removeDoc,
  };
};

export default useRemoveBorrowerDocument;
