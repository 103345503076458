import {
  compose,
  lifecycle,
  branch,
  renderComponent,
} from '@renofi/recompose/src';
import {withUser} from '@renofi/graphql/src/hoc';
import withImpersonation from '@renofi/utilities/src/withImpersonation';
import logoutWithRedirect from '@renofi/utilities/src/logoutWithRedirect';

import Component from './Component';

export default compose(
  withUser(),
  withImpersonation,
  branch(
    ({errors, user}) =>
      !user &&
      errors &&
      Boolean(errors.length) &&
      errors[0]?.message === 'AUTHENTICATION_ERROR',
    renderComponent(
      lifecycle({
        componentDidMount() {
          logoutWithRedirect();
        },
      })(() => null),
    ),
  ),
)(Component);
