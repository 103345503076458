import {pathOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';

import useMutation from '../useMutation';
import {SUBMIT_PROJECT_DOCUMENT} from '../../mutations';

export default function useSubmitProjectDocument({onCompleted = noop} = {}) {
  const [submitProjectDocument, {data, loading, error}] = useMutation(
    SUBMIT_PROJECT_DOCUMENT,
    {
      onCompleted,
    },
  );
  const response = pathOr(null, ['submitProjectDocument', 'document'], data);

  return {submitProjectDocument, response, loading, error};
}
