import React from 'react';

import PropTypes from 'prop-types';
import ManageDocuments from 'modules/tasks-wizard/components/ManageDocuments';

import {Text} from '@renofi/components-internal';
import {basic55} from '@renofi/theme/src';
import {useSubmitRealEstateOwnedPropertyDocument} from '@renofi/graphql/src';

import {Content, Title} from '../styled';

const MortgageStatementStep = ({
  task,
  realEstateOwnedPropertyId,
  address,
  documents = [],
  onUploadComplete,
}) => {
  const {submitRealEstateOwnedPropertyDocument} =
    useSubmitRealEstateOwnedPropertyDocument();
  const {id: taskId, facet} = task;

  return (
    <Content>
      <Title>
        Upload the <strong>most recent mortgage statement</strong> for {address}
        .
      </Title>
      <Text mb={24} fontSize={16} color={basic55}>
        If there is more than one mortgage on this property, include statements
        for all mortgages.
      </Text>

      <ManageDocuments
        forceRemoveButton
        customDocuments={documents}
        taskId={taskId}
        facet={facet}
        fileName="mortgage statement"
        customMutation={submitRealEstateOwnedPropertyDocument}
        customResponsePath="submitRealEstateOwnedPropertyDocument"
        customMutationParams={{
          realEstateOwnedPropertyId,
          documentType: 'mortgage_statement',
        }}
        onUploadComplete={onUploadComplete}
      />
    </Content>
  );
};

MortgageStatementStep.propTypes = {
  task: PropTypes.object,
  address: PropTypes.string,
  realEstateOwnedPropertyId: PropTypes.string,
  documents: PropTypes.array,
  onUploadComplete: PropTypes.func,
};

export default MortgageStatementStep;
