import {withRouter} from 'react-router-dom';

import {compose} from '@renofi/recompose/src';
import {withUser} from '@renofi/graphql/src/hoc';
import {withErrorBoundary} from '@renofi/components-internal/src/ErrorBoundary';
import {withConfig} from '@renofi/utilities/src/config';

import Component from './Component';

export default compose(
  withConfig,
  withErrorBoundary(),
  withRouter,
  withUser(),
)(Component);
